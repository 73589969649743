/**
 * @name LocationInfoForm
 * @description This component is used to create or update location information
 * @param {string} action - The action to take [add or update]
 * @param {number} id - The id of the location to update
 * @param {function} onSuccessfulOperation - A function to call after a successful operation
 * @param {function} onClose - A function to call after the modal is closed
 * @param {string} description - A description to display at the top of the form
 * @returns {React.Component}
 *
 * TODO:
 * - image doesnt save if it a new location. we need to save the location first then save the image
 * - add a loading spinner when the form is submitting
 *
 */

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import CardHeading from "../CardHeading";
import uploadIcon from "../../assets/images/icon-arrow-up.png";
import { GetCompany } from "../../apiCalls/CompanyCalls";
import { GetRegion } from "../../apiCalls/RegionCalls";
import {
  UpdateLocation,
  GetLocation,
  AddLocation,
} from "../../apiCalls/LocationCalls";
import { SetImage_Data } from "../../apiCalls/LocationCalls";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "../../context/AlertContext";
import { titleCase } from "../../helpers/titleCase";
import { setAllLocationInfo } from "../../redux/reducers/LocationReducer";
import { GetAllCompanies } from "../../apiCalls/CompanyCalls";
import { GetAllRegions } from "../../apiCalls/RegionCalls";



export default function LocationInfoForm(props) {
  const scope = "Location";
  const action = props.action || "update"; // decide which action to take [create or update]
  const passedInId = props.id !== undefined ? props.id : 0;
  const current_user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { addAlert } = useAlert();
  	// For managing multiple phone numbers as temporary state during form usage
	const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [logoFileName, setLogoFileName] = useState("");
  const [mapFileName, setMapFileName] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [mapFile, setMapFile] = useState(null);

  // the current user is the person who is logged in
  const current_user_role = current_user.role;
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [companyChoices, setCompanyChoices] = useState([]); // for storing companies
  const [regionChoices, setRegionChoices] = useState([]); // for storing regions
  const [allRegions, setAllRegions] = useState([]); // Store all regions

  // start form fields blank
  const [entityInfo, setEntityInfo] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phone: "",
    description: "",
    preferred_language: "",
    map: "",
    logo: "",
    video_main_english: "",
    video_main_spanish: "",
    video_truck_english: "",
    video_truck_spanish: "",
    video_guest_english: "",
    video_guest_spanish: "",
    video_contractor_english: "",
    video_contractor_spanish: "",
    company_selection:
      action === "add" ? current_user.company_id.toString() : "",
    region_selection: action === "add" ? current_user.region_id.toString() : "",
    text_notifications_number: [],
  });

  // get all companies, regions, and locations
  useEffect(() => {
    const fetchInitialData = async () => {
      const companiesResult = await GetAllCompanies();
      if (companiesResult.success) {
        setCompanyChoices(companiesResult.data);
      }

      const regionsResult = await GetAllRegions();
      if (regionsResult.success) {
        setAllRegions(regionsResult.data);
        // Filter regions based on the current user's company if the company field is locked
        const filteredRegions =
          current_user_role <= 4
            ? regionsResult.data.filter(
                (region) => region.company_id === current_user.company_id
              )
            : regionsResult.data;
        setRegionChoices(filteredRegions);
      }
    };

    fetchInitialData();

    if (action === "add") {
      setSelectedCompany(current_user.company_id.toString());
      setSelectedRegion(current_user.region_id.toString());
    } else if (action === "update") {
      GetLocation(passedInId)
        .then(async (response) => {
          if (response.success) {
            let data = response.data;

            const companyResponse = await GetCompany(data.company_id);
            const regionResponse = await GetRegion(data.region_id);

            if (companyResponse.success && regionResponse.success) {
              const parsedUrl = new URL(data.logo);
              const baseUrl = `${parsedUrl.protocol}//${parsedUrl.hostname}`;
              const logo = `${baseUrl}/${companyResponse.data.storage_name}/${regionResponse.data.storage_name}/${data.storage_name}/images/logo`;
              const map = `${baseUrl}/${companyResponse.data.storage_name}/${regionResponse.data.storage_name}/${data.storage_name}/images/map`;

			  // lets handle legacy and new video formats!
			  let video_object;
			  try {
					video_object = JSON.parse(data.video);
					if (!video_object) {
						video_object = {
							main_en: data.video || "",
							main_es: "",
							truck_en: "",
							truck_es: "",
							guest_en: "",
							guest_es: "",
							contractor_en: "",
							contractor_es: "",
						};
					}
			  } catch (e) {
					// data.video is not JSON, so it's a legacy URL
					video_object = {
						main_en: data.video || "",
						main_es: "",
						truck_en: "",
						truck_es: "",
						guest_en: "",
						guest_es: "",
						contractor_en: "",
						contractor_es: "",
					};
			  }

              setEntityInfo({
                ...data,
                logo,
                map,
				checkin_text_messages: data.checkin_text_messages ?? false,
				text_notifications_number: Array.isArray(data.text_notifications_number) ? data.text_notifications_number : [],
              });
              reset({
                name: data.name,
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip,
                email: data.email,
                phone: data.phone,
                description: data.description,
                preferred_language: data.preferred_language,
				company_selection: data.company_id.toString(),
				region_selection: data.region_id.toString(),
                video_main_english: video_object.main_en,
                video_main_spanish: video_object.main_es,
                video_truck_english: video_object.truck_en,
                video_truck_spanish: video_object.truck_es,
                video_guest_english: video_object.guest_en,
                video_guest_spanish: video_object.guest_es,
                video_contractor_english: video_object.contractor_en,
                video_contractor_spanish: video_object.contractor_es,
				checkin_text_messages: data.checkin_text_messages ?? "false",
				text_notifications_number: Array.isArray(data.text_notifications_number) ? data.text_notifications_number : [],
              });
              setValue("name", data.name);
              setValue("address", data.address);
              setValue("city", data.city);
              setValue("state", data.state);
              setValue("zip", data.zip);
              setValue("email", data.email);
              setValue("phone", data.phone);
              setValue("description", data.description);
              setValue("preferred_language", data.preferred_language);
			  setValue("video_main_english", video_object.main_en);
			  setValue("video_main_spanish", video_object.main_es);
			  setValue("video_truck_english", video_object.truck_en);
			  setValue("video_truck_spanish", video_object.truck_es);
			  setValue("video_guest_english", video_object.guest_en);
			  setValue("video_guest_spanish", video_object.guest_es);
			  setValue("video_contractor_english", video_object.contractor_en);
			  setValue("video_contractor_spanish", video_object.contractor_es);
              setValue("has_video", data.has_video.toString());
			  setValue("checkin_text_messages", data.checkin_text_messages.toString());
			  setValue("company_selection", data.company_id.toString());
			  setValue("region_selection", data.region_id.toString());
            }
          }
        })
        .catch((error) => {
          console.error(error);
          addAlert("An error occurred retrieving the data", "error", true);
        });
    }
	// eslint-disable-next-line
  }, [action, current_user, passedInId, action, reset, addAlert]);

  // Handler for company selection change
  const handleCompanyChange = (
    selectedCompanyId,
    allRegionsList = allRegions
  ) => {
    setSelectedCompany(selectedCompanyId);
    setValue("company_selection", selectedCompanyId);

    // Filter regions based on selected company
    const filteredRegions = allRegionsList.filter(
      (region) => region.company_id.toString() === selectedCompanyId
    );
    setRegionChoices(filteredRegions);

    // Reset selected region and location when company changes
    setSelectedRegion("");
  };

  // Handler for region selection change
  const handleRegionChange = (selectedRegionId) => {
    setSelectedRegion(selectedRegionId);
    setValue("region_selection", selectedRegionId);
  };

  // get the data for the form and set it

  /* * * * * * * * * * * * * * * * * * * * * * * */
  /* * * * [start] Image Helper Functions * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * */
  // get image source
  const getImageSource = (file, entityInfoImage) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    } else if (entityInfoImage && entityInfoImage.startsWith("http")) {
      return entityInfoImage;
    } else {
      return `data:image/jpeg;base64,${entityInfoImage}`;
    }
  };

  // handle file change
  const handleFileChange =
    (fileSetter, fileNameSetter, defaultFileName) => (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        fileNameSetter(selectedFile.name);
        fileSetter(selectedFile); // Set the file object directly
      } else {
        fileNameSetter(defaultFileName);
      }
    };

  // delete image file
  const deleteFile = (fileSetter, fileNameSetter, defaultFileName) => () => {
    fileSetter(null);
    fileNameSetter(defaultFileName);
  };

  // save image
  function saveImage(file_name, imageFile) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result;
      let extension = imageFile.name.split(".").pop().toLowerCase();
      if (extension === "jpg") {
        extension = "jpeg";
      }
      let to_send = {
        image: base64Image,
        type: extension,
        file_name: file_name.toLowerCase(),
        relation: "location",
        company_id: entityInfo.company_id,
        region_id: entityInfo.region_id,
        //location_id: passedInId,
        name: entityInfo.name,
        storage_name: entityInfo.storage_name,
      };
      SetImage_Data(to_send)
        .then((response) => {
          if (response.success) {
            //console.log("image saved");
          }
        })
        .catch((error) => {
          console.error(error);
          addAlert("An error occurred!", "error", true);
        });
    };
    reader.readAsDataURL(imageFile);
  }

  /* * * * * * * * * * * * * * * * * * * * * * * */
  /* * * * [end] Image Helper Functions * * * */
  /* * * * * * * * * * * * * * * * * * * * * * * */


 /* * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * [start] Text Message Helper Functions * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * */
const addPhoneNumber = () => {
    if (newPhoneNumber.trim() !== "") {
        setEntityInfo({
            ...entityInfo,
            text_notifications_number: [...entityInfo.text_notifications_number, newPhoneNumber.trim()],
        });
        setNewPhoneNumber("");
    }
};

const removePhoneNumber = (index) => {
    const updatedNumbers = entityInfo.text_notifications_number.filter((_, i) => i !== index);
    setEntityInfo({ ...entityInfo, text_notifications_number: updatedNumbers });
};


 /* * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * [end] Text Message Helper Functions * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * */



  // handle form submission
  const onSubmit = (formData) => {
    // Convert has_video from string to boolean
    formData.has_video = formData.has_video === "true";
	formData.checkin_text_messages = formData.checkin_text_messages === "true";

    // destructure logo, map, and video fields out of formData and gather the rest properties into newData
	const {
		logo, map, company_selection, region_selection,
		video_main_english, video_main_spanish,
		video_truck_english, video_truck_spanish,
		video_guest_english, video_guest_spanish,
		video_contractor_english, video_contractor_spanish,
		...newData
	  } = formData;

	// assemble video fields into an object
	const videoObject = {
		main_en: video_main_english || "",
		main_es: video_main_spanish || "",
		truck_en: video_truck_english || "",
		truck_es: video_truck_spanish || "",
		guest_en: video_guest_english || "",
		guest_es: video_guest_spanish || "",
		contractor_en: video_contractor_english || "",
		contractor_es: video_contractor_spanish || "",
	};
		  
    const data = {
		...newData,
		company_id: company_selection,
		region_id: region_selection,
		//checkin_text_messages: formData.checkin_text_messages === "true",
		text_notifications_number: entityInfo.text_notifications_number,
		video: JSON.stringify(videoObject), // stringify the video object
    };

    // Sanitize the phone number to contain only digits
    if (data.phone) {
      data.phone = data.phone.replace(/\D/g, ""); // Remove non-digit characters
    }

    // save the images if there are any to be saved - wont work on new location
    if (action === "update" && (logoFile || mapFile)) {
      if (logoFile) {
        saveImage("logo", logoFile);
      }
      if (mapFile) {
        saveImage("map", mapFile);
      }
    }

	console.log('data', data);
    // lets decide which function to call based on if were updating or creating
    let processFunction =
      action === "add"
        ? () => AddLocation(data)
        : () => UpdateLocation(passedInId, data);
    if (processFunction) {
      processFunction()
        .then(async (response) => {
          // Make this function asynchronous
          if (response.success) {
            addAlert("Settings Updated", "success", false);
            // update grid data if we came from a grid
            if (typeof props.onSuccessfulOperation === "function") {
              props.onSuccessfulOperation();
            }
            // close the modal
            if (props.onClose) {
              props.onClose();
            }

            // update state if we are updating the current location
            const updateInfo = async () => {
              if (passedInId === current_user.location_id) {
                const entityInfo = await GetLocation(passedInId);
                dispatch(setAllLocationInfo(entityInfo.data));
              }
            };

            // Call the async function
            await updateInfo();
          }
        })
        .catch((error) => {
          addAlert("An error occurred!", "error", true);
          console.error(error);
        });
    } else {
      addAlert("Invalid action or scope", "error", true);
    }
  };

  return (
    <>
      <CardHeading
        title={
          action === "add"
            ? `Add New ${titleCase(scope)}`
            : `${entityInfo.name} Information`
        }
        description={props.description || ""}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className={`half-width ${errors["name"] ? "has-error" : ""}`}>
            <label htmlFor="name">{titleCase(scope)} Name</label>
            <input
              type="text"
              name="name"
              placeholder={`name`}
              {...register("name", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
              defaultValue={entityInfo.name}
            />
          </div>
          <div className={`half-width`}>
            <label htmlFor="email">Address</label>
            <input
              type="text"
              name="address"
              placeholder={`enter address`}
              {...register("address", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
              defaultValue={entityInfo.address}
            />
          </div>
        </div>
        <div className="row">
          <div className={`half-width ${errors["city"] ? "has-error" : ""}`}>
            <label htmlFor="city">City</label>
            <input
              type="text"
              name="city"
              placeholder={`city`}
              {...register("city", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
              defaultValue={entityInfo.city}
            />
          </div>
          <div
            className={`quarter-width ${errors["state"] ? "has-error" : ""}`}
          >
            <label htmlFor="state">State</label>
            <select name="state" {...register("state", { required: true })}>
              <option value="">Select...</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AS">American Samoa</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VI">Virgin Islands</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div className={`quarter-width`}>
            <label htmlFor="zip">Zip Code</label>
            <input
              type="text"
              name="zip"
              placeholder={`enter zip`}
              {...register("zip", {
                required: true,
                minLength: 5,
                maxLength: 11,
              })}
              defaultValue={entityInfo.zip}
            />
          </div>
        </div>

        <div className="row">
          <div className={`half-width`}>
            <label htmlFor="email">Site Email</label>
            <input
              type="email"
              name="email"
              placeholder={`enter email`}
              {...register("email", {
                required: true,
                minLength: 3,
                maxLength: 65,
              })}
              defaultValue={entityInfo.email}
            />
          </div>

          <div className={`half-width`}>
            <label htmlFor="phone">Phone Number</label>
            <input
              type="phone"
              name="phone"
              placeholder={`enter phone`}
              {...register("phone", {
                required: true,
                minLength: 9,
                maxLength: 18,
              })}
              defaultValue={entityInfo.phone}
            />
          </div>
        </div>
        <div className="row">
          <div className={`full-width`}>
            <label htmlFor="description">{titleCase(scope)} Description</label>
            <textarea
              name="description"
              placeholder={`description`}
              {...register("description", {
                required: true,
                minLength: 10,
                maxLength: 350,
              })}
              defaultValue={entityInfo.description}
            ></textarea>
          </div>
        </div>
        <div className="row">
          <div
            className={`half-width ${
              errors["company_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="company_selection">
              Company {current_user_role <= 4 ? "(locked)" : ""}
            </label>
            <select
              name="company_selection"
              {...register("company_selection", { required: true })}
              onChange={(e) => handleCompanyChange(e.target.value)}
              className={current_user_role <= 4 ? "select-readonly" : ""} // Disable if user is not at least a Company Admin
              value={selectedCompany}
            >
              <option value="">Select...</option>
              {companyChoices.map((company) => (
                <option key={company.id} value={company.id}>
                  {" "}
                  {company.name}{" "}
                </option>
              ))}
            </select>
          </div>

          <div
            className={`half-width ${
              errors["region_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="region_selection">
              Region {current_user_role <= 3 ? "(locked)" : ""}
            </label>
            <select
              name="region_selection"
              {...register("region_selection", { required: true })}
              onChange={(e) => handleRegionChange(e.target.value)}
              className={current_user_role <= 3 ? "select-readonly" : ""} // Disable if user is not at least a Region Admin
              value={selectedRegion}
            >
              <option value="">Select...</option>
              {regionChoices.map((region) => (
                <option key={region.id} value={region.id}>
                  {" "}
                  {region.name}{" "}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div
            className={`half-width ${
              errors["language_selection"] ? "has-error" : ""
            }`}
          >
            <label htmlFor="language_selection">Main Language</label>
            <select
              name="preferred_language"
              {...register("preferred_language", { required: true })}
            >
              <option value="">Select...</option>
              <option value="en">English</option>
              <option value="es">Spanish</option>
            </select>
          </div>
        </div>
		<div className={`row border-top`}>
			<div className={`half-width notification-options`}>
				<label htmlFor="checkin_text_messages">Send Check In/Out Text Messages?</label>
				<div className="radio-options">
					<label>
						<input
							type="radio"
							value="true"
							{...register("checkin_text_messages")}
							// checked={entityInfo.checkin_text_messages === true}
							// onChange={() => setEntityInfo({ ...entityInfo, checkin_text_messages: true })}
						/>
						Yes
					</label>
					<label>
						<input
							type="radio"
							value="false"
							{...register("checkin_text_messages")}
							// checked={entityInfo.checkin_text_messages === false}
							// onChange={() => setEntityInfo({ ...entityInfo, checkin_text_messages: false })}
						/>
						No
					</label>
				</div>
			</div>

			<div className={`half-width notification-numbers`}>
				<label htmlFor="text_notifications_number">Notification Phone Numbers</label>
				<div className="phone-numbers">
					{entityInfo.text_notifications_number.map((number, index) => (
						<div key={index} className="phone-number">
							{number}
							<button className="button-gray" type="button" onClick={() => removePhoneNumber(index)}>Remove</button>
						</div>
					))}
					<div className="add-phone-number">
						<input
							type="text"
							value={newPhoneNumber}
							onChange={(e) => setNewPhoneNumber(e.target.value)}
							placeholder="Add new number"
						/>
						<button className="button-blue" type="button" onClick={addPhoneNumber}>Add</button>
					</div>
				</div>
			</div>


		</div>

        {/* show video info only if user is a Q4 Admin*/}
        <div className={`video-section border-top ${current_user_role < 5 ? "hide" : ""}`}>
        	<div className="row">
				<div className="half-width">
					<h3>Video Section</h3>
					<p>Enter any videos that are available. If only one, add it to Main Video URL.</p>
				</div>
				<div className={`half-width ${errors["has_video"] ? "has-error" : ""}`} >
					<label>Has Video</label>
					<div className="radio-options">
					<label>
						<input type="radio" value="true" {...register("has_video")} />
						Yes
					</label>
					<label>
						<input type="radio" value="false" {...register("has_video")} />
						No
					</label>
					</div>
				</div>
			</div>
			<div className="row">
				<div className={`half-width ${errors["video_main_english"] ? "has-error" : ""}`}>
					<label htmlFor="video_main_english">Main Video URL</label>
					<input
						type="text"
						name="video_main_english"
						placeholder="Enter video URL"
						{...register("video_main_english", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_main_english}
					/>
				</div>
				<div className={`half-width ${errors["video_main_spanish"] ? "has-error" : ""}`}>
					<label htmlFor="video_main_spanish">Main Video URL (Spanish)</label>
					<input
						type="text"
						name="video_main_spanish"
						placeholder="Enter video URL"
						{...register("video_main_spanish", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_main_spanish}
					/>
				</div>
			</div>
			<div className="row">
				<div className={`half-width ${errors["video_truck_english"] ? "has-error" : ""}`}>
					<label htmlFor="video_truck_english">Truck Driver Video URL</label>
					<input
						type="text"
						name="video_truck_english"
						placeholder="Enter video URL"
						{...register("video_truck_english", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_truck_english}
					/>
				</div>
				<div className={`half-width ${errors["video_truck_spanish"] ? "has-error" : ""}`}>
					<label htmlFor="video_truck_spanish">Truck Driver Video URL (Spanish)</label>
					<input
						type="text"
						name="video_truck_spanish"
						placeholder="Enter video URL"
						{...register("video_truck_spanish", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_truck_spanish}
					/>
				</div>
			</div>
			<div className="row">
				<div className={`half-width ${errors["video_guest_english"] ? "has-error" : ""}`}>
					<label htmlFor="video_guest_english">Guest Video URL</label>
					<input
						type="text"
						name="video_guest_english"
						placeholder="Enter video URL"
						{...register("video_guest_english", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_guest_english}
					/>
				</div>
				<div className={`half-width ${errors["video_guest_spanish"] ? "has-error" : ""}`}>
					<label htmlFor="video_guest_spanish">Guest Video URL (Spanish)</label>
					<input
						type="text"
						name="video_guest_spanish"
						placeholder="Enter video URL"
						{...register("video_guest_spanish", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_guest_spanish}
					/>
				</div>
			</div>
			<div className="row">
				<div className={`half-width ${errors["video_contractor_english"] ? "has-error" : ""}`}>
					<label htmlFor="video_contractor_english">Contractor/Vendor Video URL</label>
					<input
						type="text"
						name="video_contractor_english"
						placeholder="Enter video URL"
						{...register("video_contractor_english", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_contractor_english}
					/>
				</div>
				<div className={`half-width ${errors["video_contractor_spanish"] ? "has-error" : ""}`}>
					<label htmlFor="video_contractor_spanish">Contractor/Vendor Video URL (Spanish)</label>
					<input
						type="text"
						name="video_contractor_spanish"
						placeholder="Enter video URL"
						{...register("video_contractor_spanish", {
							required: false,
							maxLength: 255,
						})}
						defaultValue={entityInfo.video_contractor_spanish}
					/>
				</div>
			</div>
        </div>

        <div className="row border-top">
          {/* Logo Upload Section */}
          <div className="half-width">
            <div className="logo-upload-wrapper">
              <label className="custom-file-upload">
                Upload
                <img src={uploadIcon} alt="Upload Icon" width="20" />
                <input
                  type="file"
                  name="logo"
                  {...register("logo")}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange(
                    setLogoFile,
                    setLogoFileName,
                    entityInfo.logo || ""
                  )}
                />
              </label>
              <div className="file-info">
                <span>{titleCase(scope)} Logo</span>
                <small>.jpg, .jpeg, .png</small>
              </div>

              {(logoFile || entityInfo.logo) && (
                <div>
                  <img
                    src={getImageSource(logoFile, entityInfo.logo)}
                    alt="Location Logo"
                    width={100}
                  />
                  <div className="file-name">
                    {logoFileName || entityInfo.logo}
                  </div>
                  {logoFile && (
                    <button
                      type="button"
                      className="button-light"
                      onClick={deleteFile(
                        setLogoFile,
                        setLogoFileName,
                        entityInfo.logo
                      )}
                    >
                      Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Map Upload Section */}
          <div className="half-width">
            <div className="logo-upload-wrapper">
              <label className="custom-file-upload">
                Upload
                <img src={uploadIcon} alt="Upload Icon" width="20" />
                <input
                  type="file"
                  name="map"
                  {...register("map")}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange(
                    setMapFile,
                    setMapFileName,
                    entityInfo.map || ""
                  )}
                />
              </label>

              <div className="file-info">
                <span>Location Image</span>
                <small>.jpg, .jpeg, .png</small>
              </div>

              {(mapFile || entityInfo.map) && (
                <div>
                  <img
                    src={getImageSource(mapFile, entityInfo.map)}
                    alt="Location Map"
                    width={100}
                  />
                  <div className="file-name">
                    {mapFileName || entityInfo.map}
                  </div>
                  {mapFile && (
                    <button
                      type="button"
                      className="button-light"
                      onClick={deleteFile(
                        setMapFile,
                        setMapFileName,
                        entityInfo.map
                      )}
                    >
                      Delete
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="form-bottom-button-wrapper">
          <button type="submit" className="button-blue">
            {passedInId === 0 ? `Save New ${titleCase(scope)}` : `Save Changes`}
          </button>
        </div>
      </form>
      {current_user_role > 4 ? (
		<div className="location-url-row">
			<h3>Check In/Out Url</h3>
			<p>
			<a
				href={`https://www.checkin.tervuren.app/checkin/${entityInfo.company_id}/${entityInfo.region_id}/${entityInfo.id}`}
				target="_blank"
				rel="noreferrer"
			>{`https://www.checkin.tervuren.app/checkin/${entityInfo.company_id}/${entityInfo.region_id}/${entityInfo.id}`}</a>
			</p>
		</div>
      ) : null}
    </>
  );
}
