import React, { useState, useEffect } from 'react';
import RecordsLayout from '../displays/RecordsLayout';
import {GetAllTrainings} from '../../apiCalls/TrainingCalls';
import PdfIcon from '../../assets/images/icon_PDF.png';
import Modal from '../../components/Modal';
import { ViewAndDownloadPdf } from '../Modals/ViewAndDownloadPdf';
import { GetAllCompanies } from '../../apiCalls/CompanyCalls';
import { GetAllTrainees } from '../../apiCalls/TraineeCalls';
import { useSelector } from 'react-redux';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';

import ConfirmDeleteTrainingRecord from '../../components/ConfirmDeleteTrainingRecord';

function TrainingRecords(){

    const [trainings, setTrainings] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [trainees, setTrainees] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // for add training popup
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [enrichedTrainings, setEnrichedTrainings] = useState([]);
	const current_user = useSelector((state) => state.user);
	const current_user_role = current_user.role;
	const [entityType, setEntityType] = useState('location');	
	const [entityId, setEntityId] = useState(null);
    const [modalType, setModalType] = useState('edit');

	/*
    1 - 'base', // handles trainings but nothing else | scalehouse clerks or attendants
    2 - 'location-admin', // in charge of location | location manager
    3 - 'regional-admin', // in charge of region | region manager
    4 - 'company-admin', // in charge of companys and locations | stakeholder or general manager
    5 - 'super-admin', // can access anything | Q4 people

	1 or 2 can see location trainings
	3 can see region trainings
	4 can see company trainings
	5 can see all trainings
	*/
	

    function handleDelete(rowData) {
		setSelectedRowData(rowData);
        setModalType('delete');
        setIsModalOpen(true);
    }
	// function to open modal
	function handleOpenPDF(rowData) {
		setSelectedRowData(rowData.pdf);
		setModalType('pdf');
		setIsModalOpen(true);
	}
	const handleCloseModal = () => {
        setIsModalOpen(false);
    };


	// set the training records (new each time component renders this way)
	const loadData = async (newEntityType = entityType, newEntityId = entityId) => {
		// console.log(entityType, entityId);
		const trainingsResult = await GetAllTrainings(newEntityType, newEntityId);
		const companiesResult = await GetAllCompanies(newEntityType, newEntityId);
		const traineesResult = await GetAllTrainees(newEntityType, newEntityId);
		if (trainingsResult.success) {
			setTrainings(trainingsResult.data);
		}
		if (companiesResult.success) {
			setCompanies(companiesResult.data);
		}
		if (traineesResult.success) {
			setTrainees(traineesResult.data);
		}
	}

	useEffect(() => {
		// Set the entity type and id based on the current user's role
		const userRole = parseInt(current_user.role);
		let newEntityType = 'location';
		let newEntityId = null;
	
		if (userRole === 1 || userRole === 2) {
			newEntityType = 'location';
			newEntityId = current_user.location_id;
		} else if (userRole === 3) {
			newEntityType = 'region';
			newEntityId = current_user.region_id;
		} else if (userRole === 4) {
			newEntityType = 'company';
			newEntityId = current_user.company_id;
		} else if (userRole === 5) {
			newEntityType = '';
			newEntityId = null;
		}
	
		
		setEntityType(newEntityType);
		setEntityId(newEntityId);
		
		(async () => {
			await loadData(newEntityType, newEntityId);
		})();
	}, [current_user]); 	


    function formatDate(timestamp) {
        const date = new Date(timestamp);
        const day = date.getDate();
        const month = date.getMonth() + 1; // getMonth() returns 0-11
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }

    function getStatus(dateCompleted) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        return dateCompleted > oneYearAgo.getTime() ? 'active' : 'expired';
    }
    

    useEffect(() => {
        // This effect is only for enriching trainings data
        if (companies.length > 0 && trainees.length > 0 && trainings.length > 0) {
            // sort trainings by date_completed in descending order
            const sortedTrainings = [...trainings].sort((a, b) => b.date_completed - a.date_completed);

            const newEnrichedTrainings = sortedTrainings.map(training => {
                const company = companies.find(c => c.id === training.company_id);
                const trainee = trainees.find(t => t.id === training.trainee_id);
    
                return {
                    ...training,
                    training_company: company ? company : '',
                    trainee_first_name: trainee ? trainee.first_name : '',
                    trainee_last_name: trainee ? trainee.last_name : '',
                    trainee_phone: trainee ? trainee.phone : '',
                    trainee_email: trainee ? trainee.email : '',
                    trainee_visitor_type: trainee ? trainee.visitor_type : '',
                    trainee_company_name: trainee ? trainee.company : '',
                    date_completed_formatted: formatDate(training.date_completed), // formatted date
                    status: getStatus(training.date_completed) // 'active' or 'expired'
                };
            });
    
            setEnrichedTrainings(newEnrichedTrainings);
        }
    }, [companies, trainees, trainings]); // Include trainings in dependency array
    
    
    const gridStyle = { minHeight: 550, width:'100%' }

    const defaultColumns = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 50 },
        { name: 'company_id', header: 'Company ID', defaultVisible: false, type: 'number',  defaultFlex: 2 },
        { name: 'region_id', header: 'Region ID', defaultVisible: false, type: 'number',  defaultFlex: 2 },
        { name: 'trainee_first_name', header: 'First Name', group:'personalInfo', defaultFlex: 2 },
        { name: 'trainee_last_name', header: 'Last Name', group:'personalInfo', defaultFlex: 2 },
        { name: 'trainee_phone', header: 'Phone', group:'personalInfo', defaultFlex: 2 },
        { name: 'trainee_email', header: 'Email', group:'personalInfo', defaultFlex: 2 },
        { name: 'date_completed_formatted', header: 'Training Date', defaultFlex: 2 },

        {   name: 'pdf',
            header: 'PDF', 
            render: ({ data }) => (
                <div style={{ display: 'flex' }}> 
                    <img src={PdfIcon} className="pdf-icon" alt="View PDF" onClick={() => handleOpenPDF(data)}/>                  
                </div>
            ),
            defaultFlex: 1 ,
            
        },

        { name: 'trainee_company_name', header: 'Company Name', type: 'number',  defaultFlex: 2 },
        { name: 'trainee_visitor_type', header: 'Visitor Type', defaultFlex: 2 },
        { name: 'status', header: 'Status', defaultFlex: 2,
        render: ({ value }) => {
            const style = { color: value === 'active' ? 'green' : 'red' };
            return <span style={style}>{value}</span>;
        } 
    },
	{
        name: 'alter',
        header: 'Alter',
        defaultFlex: 1,
        render: ({ data }) => {
            const canDelete = current_user_role >= 2;
            return (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    {canDelete && (
                        <img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDelete(data)} />
                    )}
                </div>
            );
        },
    },

    ];

	
	/* -----------------------------------------------------
	* [start] handle column order change
	*/

	const [gridKey, setGridKey] = useState(0); // this is used to manually update/rerender the data grid when needed
	const [columns, setColumns] = useState(defaultColumns);
	const localStorageKey = `trainingRecordsColumns_${current_user.id}`;
	const [columnsSaved, setColumnsSaved] = useState(false);
	
	useEffect(() => {
		const savedColumns = localStorage.getItem(localStorageKey);
		if (savedColumns) {
			const parsedColumns = JSON.parse(savedColumns);
			const columnsWithRenderFunctions = parsedColumns.map(col => {
				const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
				return defaultCol ? { ...col, render: defaultCol.render } : col;
			});
			setColumns(columnsWithRenderFunctions);
			setColumnsSaved(true);
		}
	}, []);
	
	const handleColumnChange = (newColumns) => {
		const columnsToSave = newColumns.map(col => {
			const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
			return defaultCol ? { ...col, render: defaultCol.render } : col;
		});
		setColumns(columnsToSave);
		localStorage.setItem(localStorageKey, JSON.stringify(columnsToSave));
		setColumnsSaved(true);
	};
	

	const resetColumns = () => {
		localStorage.removeItem(localStorageKey);
		setColumns(defaultColumns);
		setColumnsSaved(false);
		setGridKey(prevKey => prevKey + 1); // Update the key to force remount
	};

	/*
	* [end] handle column order change
	* -----------------------------------------------------
	*/


    // return veiw to be rendered
    return (
        <>
        <RecordsLayout
			key={gridKey}
            title='Training Records'
            addRecordType = 'Training'
            style={gridStyle}
            columns={columns}
            dataSource={enrichedTrainings}
            exportType="package"
            exportFileName='Training-Records-Export.zip'
			recordType='Training'
			onSuccessfulOperation={() => loadData(entityType, entityId)}
			onColumnChange={handleColumnChange}
			onResetColumns={resetColumns}
			columnsSaved={columnsSaved}
        />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} >
			{modalType==='pdf' && <ViewAndDownloadPdf data={selectedRowData}/>}
            {modalType==='delete' && <ConfirmDeleteTrainingRecord onClose={handleCloseModal} trainingData={selectedRowData} onSuccessfulOperation={loadData}/>}
        </Modal>
        </>
    )
}

export default TrainingRecords  