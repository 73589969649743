import React, { useState, useEffect, createContext, useCallback } from "react";
import { GetService } from "../services/GeneralServices";
import { PostService } from "../services/GeneralServices";
import { DeleteService } from "../services/GeneralServices";
import { PutService } from "../services/GeneralServices";
import { useSelector } from "react-redux";

const MessagingContext = createContext();

export function MessagingProvider({ children }) {
  const [messages, setMessages] = useState([]);
  const [automaticMessages, setAutomaticMessages] = useState([]);
  const location_id = useSelector((state) => state.location.id);

  const updateMessagesState = useCallback((newMessages) => {
    setMessages(newMessages);
  }, []);

  const updateAutomaticMessagesState = useCallback((newAutomaticMessages) => {
	setAutomaticMessages(newAutomaticMessages);
  }, []);

  const addMessageDB = async (newMessage) => {
    try {
      let data = await PostService("text-messages", newMessage);
      setMessages([...messages, data]);
    } catch (error) {
      console.error("Error adding message:", error); // Debugging line
    }
  };

  const deleteMessageDB = async (id) => {
    try {
      await DeleteService("text-message/id", id);
      setMessages(messages.filter((message) => message.id !== id));
    } catch (error) {
      console.error("Error deleting message:", error); // Debugging line
    }
  };

  const updateMessageDB = async (id, updatedMessage) => {
    try {
      let data = await PutService("text-message/id", id, updatedMessage);
      setMessages(
        messages.map((message) => (message.id === id ? data : message))
      );
    } catch (error) {
      console.error("Error updating message:", error); // Debugging line
    }
  };

  useEffect(() => {
    const fetchMessage = async () => {
      try {
        let data = await GetService(`text-messages/location/${location_id}`);
        let automaticMessages = await GetService(
          `automatic-messages/location/${location_id}`
        );
        setAutomaticMessages(automaticMessages);
        setMessages(data);
      } catch (error) {
        console.error("Error fetching messages:", error); // Debugging line
      }
    };

    fetchMessage();
  }, []);

  return (
    <MessagingContext.Provider
      value={{
        messages,
        automaticMessages,
        setAutomaticMessages,
        setMessages,
        updateMessagesState,
		updateAutomaticMessagesState,
        updateMessageDB,
        deleteMessageDB,
        addMessageDB,
      }}
    >
      {children}
    </MessagingContext.Provider>
  );
}

export default MessagingContext;
