import Navigation from "../../components/Navigation";

function AdminLayout({ children }) {
  // return view to be rendered
  return (
    <>
      <Navigation></Navigation>

      <div className="main-container-background-dark">
        <div className="dark-bg-img-wrapper">
          <img src="https://tervuren-company-objects.s3.us-east-2.amazonaws.com/q4impact/images/topo-line-art.png"></img>
        </div>
        <div className="main-container">{children}</div>
      </div>
    </>
  );
}

export default AdminLayout;
