import React, { useEffect} from "react";
import { useForm } from "react-hook-form";
import CardHeading from "../CardHeading";
import "react-datetime/css/react-datetime.css"; // for datepicker

function MessageInfoForm(props) {
	const action = props.action.trim() || "update";
	const message = props.message || {};

	// set card and button title based on new or update
	const cardTitle = action === "update" ? "Edit Message" : "Add Message";
	const buttonTitle = action === "update" ? "Edit Message" : "Add Message";

	// set default values for form
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
		title: "",
		message_text: "",
		},
	});


	useEffect(() => {
		setValue("title", message.title);
		setValue("message_text", message.text);
	}, [message, setValue]);

	const onSubmit = (formData) => {
		// Construct the submission object with the updated values
		message.title = formData.title;
		message.text = formData.message_text;
		if(props.onSuccessfulOperation){
			props.onSuccessfulOperation(message);
		}
	};

	return (
		<>
		<CardHeading title={cardTitle} />

		<form>
			<div className="row">
				<div className={`half-width ${errors["title"] ? "has-error" : ""}`} >
				<label htmlFor="title">Title</label>
				<input
					type="text"
					name="title"
					placeholder={`Title`}
					{...register("title", {
						required: true,
						minLength: 3,
						maxLength: 65,
					})}
				/>
				</div>
			</div>
			<div className="row">
				<div className={`half-width ${errors["message_text"] ? "has-error" : ""}`} >
					<label htmlFor="message_text">Message Text</label>
					<input
						type="textarea"
						name="message_text"
						placeholder={`Message Text`}
						{...register("message_text", {
							required: true,
							minLength: 3,
							maxLength: 65,
						})}
					/>
				</div>
			</div>

			<div className="form-bottom-button-wrapper">
				<button type="submit" className="button button-blue" onClick={handleSubmit(onSubmit)} >
					{buttonTitle}
				</button>
			</div>
		</form>
		</>
  );
}

export default MessageInfoForm;
