import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import CardHeading from '../../components/CardHeading';
import {GetTrainee, UpdateTrainee} from '../../apiCalls/TraineeCalls';
import { GetAllLocations } from '../../apiCalls/LocationCalls';
import { useSelector } from 'react-redux';
import { useAlert } from '../../context/AlertContext';

function TraineeInfoForm(props) {

    const { register, setValue, handleSubmit, formState: { errors } } = useForm(); // for form control
	const [locationChoices, setLocationChoices] = useState([]); // for storing locations
	const trainee_id = props.id;
	const [selectedLocation, setSelectedLocation] = useState("");
	// the current user is the person who is logged in
	const current_user = useSelector((state) => state.user);
	const current_user_role = current_user.role;
	const [allLocations, setAllLocations] = useState([]); // for storing all locations
	const { addAlert } = useAlert();

	// get trainee info
	useEffect(() => {
		async function fetchTrainee() {
			const result = await GetTrainee(trainee_id);
			if (result.success) {
				// Set form values
				let data = result.data;
				setValue("first_name", data.first_name);
				setValue("last_name", data.last_name);
				setValue("email", data.email);
				setValue("phone", data.phone);
				setValue("company", data.company);
				setValue("visitor_type", data.visitor_type);
				setValue("msha_number", data.msha_number);
				setValue("truck_number", data.truck_number);
				setValue("preferred_language", data.preferred_language);
				setValue("location_selection", data.location_id.toString());
				setSelectedLocation(data.location_id.toString());
			}
		}
		fetchTrainee();
		
	}, [trainee_id, setValue]);

	// get relevant location
	useEffect(() => {
		const fetchInitialData = async () => {
			var location_type = '';
			var location_id = '';
			
			if(current_user_role < 3) {
				// single location
				location_type = 'location';
				location_id = current_user.location_id;
			}else if(current_user_role === 3) {	
				// region
				location_type = 'region';
				location_id = current_user.region_id;
			}else if(current_user_role === 4) {
				// company
				location_type = 'company';
				location_id = current_user.company_id;
			}else if(current_user_role === 5) {
				// super admin
				location_type = 'global';
				location_id = null;
			}
		  const locationsResult = await GetAllLocations(location_type, location_id);
		  if (locationsResult.success) {
			setAllLocations(locationsResult.data);
			setLocationChoices(locationsResult.data);
		  }
		};
	
		fetchInitialData();
	
	
	  }, [current_user, current_user_role]);

	// handle select change
	const handleLocationChange = (selectedLocationId) => {
		setSelectedLocation(selectedLocationId);
	};

	const onSubmit = (formData) => {
		const location = allLocations.find((location) => location.id === formData.location_selection);

		if (!location) {
			addAlert("Selected location not found. Please try again.", "error", true);
			return; // Exit the function to prevent further execution
		}

		var submitData = {
			"first_name": formData.first_name,
			"last_name": formData.last_name,
			"email": formData.email,
			"phone": formData.phone,
			"company": formData.company,
			"visitor_type": formData.visitor_type,
			"msha_number": formData.msha_number,
			"truck_number": formData.truck_number,
			"preferred_language": formData.preferred_language,
			"location_id": formData.location_selection,
        "region_id": location.region_id,
        "company_id": location.company_id,
		};
		// update the user
		UpdateTrainee(trainee_id, submitData).then(async (result) => {
			if (result.success) {
			  addAlert("Trainee Updated", "success", false);
			  // update grid data if we came from a grid
			  if (typeof props.onSuccessfulOperation === "function") {
				props.onSuccessfulOperation();
			  }
			  if (props.onClose) {
				props.onClose(); // close the modal
			  }
			} else {
			  addAlert("An error occurred!", "error", true);
			  console.log(result.message);
			}
		  });
	}
    return (
        <>
        <CardHeading title='Edit Trainee'/>

        <form>
            <div className="row">
                <div className={`half-width ${errors["first_name"] ? "has-error" : ''}`}>
                    <label htmlFor="first_name">First Name *</label>
                    <input type="text" name="first_name" placeholder={`First Name`} {...register('first_name', {required: true, minLength: 3, maxLength: 65})}/>
                </div>
                <div className={`half-width ${errors["last_name"] ? "has-error" : ''}`}>
                    <label htmlFor="last_name">Last Name *</label>
                    <input type="text" name="last_name" placeholder={`Last Name`} {...register('last_name', {required: true, minLength: 3, maxLength: 65})}/>
                </div>
            </div>

            <div className="row">
                <div className={`half-width`}>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" placeholder={`Email`} {...register('email', {required: false, minLength: 3, maxLength: 65})}/>
                </div>

                <div className={`half-width`}>
                    <label htmlFor="phone">Phone</label>
                    <input type="phone" name="phone" placeholder={`Phone`} {...register('phone', {required: true, minLength: 10, maxLength: 18})}/>
                </div>
            </div>

            <div className="row">
                <div className={`half-width ${errors["company"] ? "has-error" : ''}`}>
                    <label htmlFor="company">Company *</label>
                    <input type="text" name="company" placeholder={`Company`} {...register('company', {required: true, minLength: 3, maxLength: 65})}/>
                </div>
                <div className={`half-width ${errors["visitor_type"] ? "has-error" : ''}`}>
                    <label htmlFor="visitor_type">Visitor Type *</label>
                    <select name="visitor_type" {...register('visitor_type', {required: true})}>
                        <option value="">Please Select</option>
                        <option value="truck">Truck Driver</option>
                        <option value="contractor">Contractor / Vendor</option>
                        <option value="guest">Guest</option>
                    </select>
                </div>
            </div>

            <div className="row">
                <div className={`half-width`}>
                    <label htmlFor="msha_number">MSHA Number</label>
                    <input type="text" name="msha_number" placeholder={`MSHA Number`} {...register('msha_number', {required: false, minLength: 3, maxLength: 25})}/>
                </div>
                <div className={`half-width`}>
                    <label htmlFor="truck_number">Truck Number</label>
                    <input type="text" name="truck_number" placeholder={`Truck Number`} {...register('truck_number', {required: false, minLength: 3, maxLength: 25})}/>
                </div>
            </div>

            <div className="row">
                <div className={`half-width ${errors["preferred_language"] ? "has-error" : ''}`}>
                    <label htmlFor="preferred_language">Preferred Language</label>
                    <select name="preferred_language" {...register('preferred_language', {required: false})}>
                        <option value="">Please Select</option>
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                    </select>
                </div>
				<div className={`half-width ${ errors["location_selection"] ? "has-error" : "" }`}>
            		<label htmlFor="location_selection">Location</label>
					<select
						name="location_selection"
						{...register("location_selection", { required: true })}
						disabled={current_user_role <= 2} // Disable if no region is selected or user is not a Region Manager
						value={selectedLocation}
						onChange={(e) => handleLocationChange(e.target.value)}
						>
						<option value="">Select...</option>
						{locationChoices.map((location) => (
							<option key={location.id} value={location.id}>
							{" "}
							{location.name}{" "}
							</option>
						))}
					</select>
				</div>
                

            </div>
            
            

            <div className="form-bottom-button-wrapper">
                <button type="submit" className="button button-blue" onClick={handleSubmit(onSubmit)}>Edit Trainee</button>
            </div>
        </form>
        </>
    );
}

export default TraineeInfoForm;
