import React, { useState, useEffect } from 'react';
import Alert from './AlertComponent/Alert';

const CheckinBanner = ({ wsUrl }) => {
  const [message, setMessage] = useState('');
  const [showBanner, setShowBanner] = useState(false);
  let type;

  useEffect(() => {
    let ws; // Define WebSocket instance at higher scope for easy reference
    let pingInterval, checkInterval; // Define intervals at higher scope

    const connectWebSocket = () => {

      // Close existing connection if any before creating a new one
      if (ws) {
        ws.close();
      }

      ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        // console.log('WebSocket connected to:', wsUrl);
      };

      ws.onmessage = (event) => {

        // console.log('Message from server:', event.data);
        setMessage(event.data);
        setShowBanner(true);
        setTimeout(() => setShowBanner(false), 7000);

      };

      //when the user click to a new page on my website the websocket connection is closed
      window.onbeforeunload = () => {
        ws.close();
      };

      ws.onclose = (event) => {

        // console.log('WebSocket disconnected. Attempting to reconnect...');
        if (!ws) {
          setTimeout(connectWebSocket, 5000);
        }


      };

      ws.onerror = (event) => {
        console.error('WebSocket error observed:', event);
      };
    };

    connectWebSocket(); // Initial connection setup

    // Setup ping interval
    pingInterval = setInterval(() => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ action: 'ping' }));
      }
    }, 20000);

    // Cleanup function to close websocket connection and clear intervals when component unmounts
    return () => {
      clearInterval(pingInterval);
      if (ws) {
        ws.close();
      }


    };
  }, [wsUrl]); // Re-run the effect if wsUrl changes

  if (!showBanner) return null;
  if (message.includes('checked in')) {
    type = 'checkin';
  } else {
    type = 'checkout';
  }
  return (
    <Alert message={message} type={type} onDismiss={() => setShowBanner(false)} />
  );
}

export default CheckinBanner;
