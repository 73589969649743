import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: 0,
  name: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  email: "",
  phone: "",
  logo: "",
  map: "",
  blank_pdf: "",
  description: "",
  has_agreement: false,
  agreement: null,
  has_video: false,
  video: "",
  company_id: 0,
  preferred_language: "en",
};

/**
 * Create a slice as a reducer containing actions.
 */
export const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    setAllRegionInfo: (state, action) => {
      state.id = action.payload.id || 0;
      state.name = action.payload.name || "";
      state.address = action.payload.address || "";
      state.city = action.payload.city || "";
      state.state = action.payload.state || "";
      state.zip = action.payload.zip || "";
      state.email = action.payload.email || "";
      state.phone = action.payload.phone || "";
      state.logo = action.payload.logo || "";
      state.map = action.payload.map || "";
      state.blank_pdf = action.payload.blank_pdf || "";
      state.description = action.payload.description || "";
      state.preferred_language =
        action.payload.preferred_language || initialState.preferred_language;
      state.has_agreement = action.payload.has_agreement || false;
      state.agreement = action.payload.agreement || null;
      state.has_video = action.payload.has_video || false;
      state.video = action.payload.video || "";
      state.company_id = action.payload.company_id || 0;
      state.storage_name = action.payload.storage_name ?? "";
    },

    setRegionName: (state, action) => {
      state.name = action.payload.name;
    },

    setRegionConnectionResponse: (state, action) => {
      state.connectionResponse = action.payload;
    },

    resetRegionState: () => initialState, // Reset to initial state
  },
});

export const {
  setAllRegionInfo,
  setRegionName,
  setRegionConnectionResponse,
  resetRegionState,
} = regionSlice.actions;
export default regionSlice.reducer;
