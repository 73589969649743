import React, { useState, useEffect } from 'react';
import RecordsLayout from '../displays/RecordsLayout';
import {GetAllTrainings} from '../../apiCalls/TrainingCalls';
import Modal from '../../components/Modal';
import { GetAllTrainees } from '../../apiCalls/TraineeCalls';
import { GetAllLocations } from '../../apiCalls/LocationCalls';
import { useSelector } from 'react-redux';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import PenIcon from '../../assets/images/icon-pen-indigo.png';
import ConfirmDeleteTrainee from '../../components/ConfirmDeleteTrainee';
import TraineeInfoForm from '../../components/settings/TraineeInfoForm';

function TraineesList(){

    const [trainings, setTrainings] = useState([]);
    const [trainees, setTrainees] = useState([]);
	const [locations, setLocations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
	const [enrichedTrainees, setEnrichedTrainees] = useState([]);
	const current_user = useSelector((state) => state.user);
	const [entityType, setEntityType] = useState('location');	
	const [entityId, setEntityId] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
    const [modalType, setModalType] = useState('edit');
	const current_user_role = current_user.role;
	const localStorageKey = `traineesListColumns_${current_user.id}`;
	const [columnsSaved, setColumnsSaved] = useState(false);


	// set the training records (new each time component renders this way)
	const loadData = async (newEntityType = entityType, newEntityId = entityId) => {
		const trainingsResult = await GetAllTrainings(newEntityType, newEntityId);
		const traineesResult = await GetAllTrainees(newEntityType, newEntityId);
		const locationsResult = await GetAllLocations(newEntityType, newEntityId);
		if (trainingsResult.success) {
			setTrainings(trainingsResult.data);
		}
		if (traineesResult.success) {
			setTrainees(traineesResult.data);
			console.log('traineesResult.data', traineesResult.data);
		}
		if (locationsResult.success) {
			setLocations(locationsResult.data);
		}
	}

	useEffect(() => {
		// Set the entity type and id based on the current user's role
		const userRole = parseInt(current_user.role);
		let newEntityType = 'location';
		let newEntityId = null;
	
		if (userRole === 1 || userRole === 2) {
			newEntityType = 'location';
			newEntityId = current_user.location_id;
		} else if (userRole === 3) {
			newEntityType = 'region';
			newEntityId = current_user.region_id;
		} else if (userRole === 4) {
			newEntityType = 'company';
			newEntityId = current_user.company_id;
		} else if (userRole === 5) {
			newEntityType = '';
			newEntityId = null;
		}
	
		
		setEntityType(newEntityType);
		setEntityId(newEntityId);
		
		(async () => {
			await loadData(newEntityType, newEntityId);
		})();
	}, [current_user]); 	


	function getStatus(dateCompleted) {
		const oneYearAgo = new Date();
		oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
		return dateCompleted > oneYearAgo.getTime() ? 'active' : 'expired';
	}
	
    
	useEffect(() => {
        if (trainees.length > 0 && trainings.length > 0 && locations.length > 0) {
            const newEnrichedTrainees = trainees.map(trainee => {
                const newestTraining = trainings.find(training => training.trainee_id === trainee.id);
				const location = locations.find(location => location.id === trainee.location_id);
				const newestTrainingDate = newestTraining ? new Date(newestTraining.date_completed).toLocaleDateString() : 'expired';
            	const status = newestTraining ? getStatus(newestTraining.date_completed) : 'no training';
				if (status === 'expired') {
					console.log({
						trainee_id: trainee.id,
						first_name: trainee.first_name,
						last_name: trainee.last_name,
						newestTrainingDate: trainee.newestTrainingDate
					});
				}
                return {
                    ...trainee,
					location_trained: location ? location.name : 'N/A',
					trainee_company_name: trainee.display_company,
					newestTrainingDate: newestTrainingDate,
					status: status
                    // newestTrainingDate: newestTraining ? new Date(newestTraining.date_completed).toLocaleDateString() : 'Expired',
                    // status: newestTraining ? getStatus(newestTraining.date_completed) : 'Expired'
                };
            });
            setEnrichedTrainees(newEnrichedTrainees);
        }
    }, [trainees, trainings, locations]);


	function handleEdit(rowData) {
        setSelectedRowId(rowData.id);
        setModalType('edit');
        setIsModalOpen(true);
    }

    function handleDelete(rowData) {
        setSelectedRowId(rowData.id);
        setModalType('delete');
        setIsModalOpen(true);
    }
    
	function handleCloseModal() {
		setIsModalOpen(false);
	}	


    const gridStyle = { minHeight: 550, width:'100%' }

	const defaultColumns = [
		{ name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 50 },
		{ name: 'company_id', header: 'Company ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
		{ name: 'region_id', header: 'Region ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
		{ name: 'location_id', header: 'Region ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
		{ name: 'first_name', header: 'First Name', defaultFlex: 2 },
		{ name: 'last_name', header: 'Last Name', defaultFlex: 2 },
		{ name: 'phone', header: 'Phone', defaultFlex: 2 },
		{ name: 'email', header: 'Email', defaultFlex: 2 },
		{ name: 'trainee_company_name', header: 'Trainee Company', type: 'number', defaultFlex: 2 },
		{ name: 'visitor_type', header: 'Visitor Type', defaultFlex: 2 },
		{ name: 'location_trained', header: 'Location', defaultFlex: 2 },
		{
			name: 'status', header: 'Status', defaultFlex: 2,
			render: ({ value }) => {
				const style = { color: value === 'active' ? 'green' : 'red' };
				return <span style={style}>{value}</span>;
			} 
		},
		{
			name: 'alter', header: 'Alter', defaultFlex: 1, render: ({ data }) => {
				const canEdit = current_user_role >= 2;
				const canDelete = current_user_role >= 2;
				return (
					<div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
						{canEdit && (
							<img src={PenIcon} className="pen-icon" alt="Edit" onClick={() => handleEdit(data)} />
						)}
						{canDelete && (
							<img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDelete(data)} />
						)}
					</div>
				);
			}
		},
	];

	/* -----------------------------------------------------
	* [start] handle column order change
	*/

	const [gridKey, setGridKey] = useState(0); // this is used to manually update/rerender the data grid when needed
	const [columns, setColumns] = useState(defaultColumns);
	
	useEffect(() => {
		const savedColumns = localStorage.getItem(localStorageKey);
		if (savedColumns) {
			const parsedColumns = JSON.parse(savedColumns);
			const columnsWithRenderFunctions = parsedColumns.map(col => {
				const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
				return defaultCol ? { ...col, render: defaultCol.render } : col;
			});
			setColumns(columnsWithRenderFunctions);
			setColumnsSaved(true);
		}
	}, []);
	
	const handleColumnChange = (newColumns) => {
		const columnsToSave = newColumns.map(col => {
			const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
			return defaultCol ? { ...col, render: defaultCol.render } : col;
		});
		setColumns(columnsToSave);
		localStorage.setItem(localStorageKey, JSON.stringify(columnsToSave));
		setColumnsSaved(true);
	};
	

	const resetColumns = () => {
		localStorage.removeItem(localStorageKey);
		setColumns(defaultColumns);
		setColumnsSaved(false);
		setGridKey(prevKey => prevKey + 1); // Update the key to force remount
	};

	/*
	* [end] handle column order change
	* -----------------------------------------------------
	*/
	

    // return veiw to be rendered
    return (
        <>
        <RecordsLayout
			key={gridKey}
            title='Trainees List'
            addRecordType = 'Training'
            style={gridStyle}
            columns={columns}
            // groups={groups}
            dataSource={enrichedTrainees}
            exportType="csv"
            exportFileName='Trainee-Records.csv'
			recordType='Trainee'
			onSuccessfulOperation={() => loadData(entityType, entityId)}
			onColumnChange={handleColumnChange}
			onResetColumns={resetColumns}
			columnsSaved={columnsSaved}
        />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} >
			{modalType==='edit' && <TraineeInfoForm onClose={handleCloseModal} id={selectedRowId} onSuccessfulOperation={loadData}/>}
            {modalType==='delete' && <ConfirmDeleteTrainee onClose={handleCloseModal} id={selectedRowId} onSuccessfulOperation={loadData}/>}
        </Modal>
        </>
    )
}

export default TraineesList  