/* 
    this is used for only global users. 
    passed in via scope: global
*/

import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import CardHeading from '../CardHeading';
import {GetAllCompanies} from '../../apiCalls/CompanyCalls';
import Modal from '../Modal';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import PenIcon from '../../assets/images/icon-pen-indigo.png';
import CompanyInfoForm from './CompanyInfoForm';
import ConfirmDelete from '../ConfirmDelete';
import { useSelector } from 'react-redux';


function CompaniesList(props){


	const current_user = useSelector((state) => state.user);
	const current_user_role = current_user.role;
    const [gridData, setGridData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [selectedRowId, setSelectedRowId] = useState(0);
    const [modalType, setModalType] = useState('edit');

    function handleAdd() {   
        setModalType('add');
        //setSelectedRowId(0); // flag for new record
        setIsModalOpen(true);
    }

    function handleEdit(rowData) {
        setSelectedRowId(rowData.id);
        setModalType('edit');
        setIsModalOpen(true);
    }

    function handleDelete(rowData) {
        setSelectedRowId(rowData.id);
        setModalType('delete');
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    // load all comapanies information
    const loadData = async () => {
        const result = await GetAllCompanies();
        if (result.success) {
            setGridData(result.data);
        }else{
            console.log(result.message);
        }
    };

    // get the data for the grid and set it
    useEffect(() => {
          
        loadData();
    }, []);

    const gridStyle = { minHeight: 350 }
    const columns = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth:100 },
        { name: 'name', header: 'Company Name', defaultFlex: 2 },
        { name: 'address', header: 'Address', defaultFlex: 2},
        { name: 'state', header: 'State', defaultFlex: 1},
        { name: 'zip', header: 'Zip Code', defaultFlex: 1},
        { name: 'email', header: 'Email', defaultFlex: 2 },
        { name: 'phone', header: 'Phone', defaultFlex: 2 },
        {
            header: 'Alter',
            render: ({ data }) => (
                <div style={{ display: 'flex', justifyContent:'center', gap: '10px' }}> 
                    <img src={PenIcon} className="pen-icon" alt="Edit" onClick={() => handleEdit(data)}/>
					{current_user_role > 4 && <img src={DeleteIcon} className='delete-icon' alt="delete" onClick={() => handleDelete(data)} />
		  }                   
                </div>
            ),
            defaultFlex: 1,
        },
    ];
 
    // return veiw to be rendered
    return (
        <>
        <CardHeading 
            title='Companies List'
            description={props.description || ''}
        />
            <div className='card-list-wrapper'>
            <ReactDataGrid
                idProperty="id"
                style={gridStyle}
                columns={columns}
                dataSource={gridData}
            />
            </div>
            <div className='add-record-button-wrapper'>
                <button type="button" className="button-blue add-record-button" onClick={() => handleAdd()}>
                    Add Company
                </button>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {modalType==='add' && <CompanyInfoForm action='add' onClose={handleCloseModal} id={selectedRowId} onSuccessfulOperation={loadData}/>}
                {modalType==='edit' && <CompanyInfoForm action='update' onClose={handleCloseModal} id={selectedRowId} onSuccessfulOperation={loadData}/>}
                {modalType==='delete' && <ConfirmDelete scope='company' onClose={handleCloseModal} id={selectedRowId} onSuccessfulOperation={loadData}/>}
            </Modal>
        </>
    )
}

export default CompaniesList