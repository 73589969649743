import HttpService from "./HttpService";

/* Generic functions to handle various types of requests */

export const PostService = (route, data) => {
  const http = new HttpService();
  let url = `${route}`;
  return http
    .postData(data, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// Generic function to handle GET requests
export const GetService = (route) => {
  const http = new HttpService();
  let url = `${route}`;
  return http
    .getData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const PutService = (route, data) => {
  const http = new HttpService();
  let url = `${route}`;
  return http
    .putData(data, url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const DeleteService = (route) => {
  const http = new HttpService();
  let url = `${route}`;
  return http
    .deleteData(url)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// function for handling file downloads
// export const FileService = (baseRoute, route, data) => {
//     const http = new HttpService();
//     let url = `${baseRoute}/${route}`;

//     // Use postDataForFile method from HttpService
//     return http.postDataForFile(data, url).then(blob => {
//         // The response is expected to be a blob (file data)
//         return blob;
//     }).catch((error) => {
//         return error;
//     });
// }

// export const PostMultiPartDataService = (baseRoute, route, data) => {
//     const http = new HttpService();
//     let url = `${baseRoute}/${route}`; // dynamically create the URL based on the base route and the provided route
//     return http.postMultiPartData(data, url).then((data) => {
//         return data;
//     }).catch((error) => {
//         return error;
//     });
// }
