import { useContext, useState, useEffect } from "react";
import MessagingContext from "../../context/MessagingProvider";
import TraineeContext from "../../context/TraineeProvider";
import { DeleteService, PostService, PutService } from "../../services/GeneralServices";
import AutomaticMessageForm from "./AutomaticMessageForm";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import CardHeading from '../CardHeading';
import Modal from '../Modal';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import PenIcon from '../../assets/images/icon-pen-indigo.png';
import { useAlert } from "../../context/AlertContext";
import AutomaticMessageConfirmDelete from "./AutomaticMessageConfirmDelete";
import { useSelector } from 'react-redux';


const AutomaticMessagesList = () => {
	const [gridData, setGridData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [modalType, setModalType] = useState('edit');
	const { addAlert } = useAlert();
	const [messageToAlter, setMessageToAlter] = useState(null);
	const { messages, automaticMessages, setAutomaticMessages, updateAutomaticMessagesState } = useContext(MessagingContext);
	const { trainees } = useContext(TraineeContext);
	const location_id = useSelector((state) => state.location.id);

	// update gridData when messages change
	useEffect(() => {
		setGridData(automaticMessages);
	}, [automaticMessages]);

	const getMessageById = (id) => {
		const message = messages.find(m => m.id === id);
		setMessageToAlter(message);
	}

    function handleAdd() {   
        setModalType('add');
        setIsModalOpen(true);
    }

    function handleEdit(rowData) {
        getMessageById(rowData.id);
		setMessageToAlter(rowData);
        setModalType('edit');
        setIsModalOpen(true);
    }

    function handleDelete(rowData) {
        getMessageById(rowData.id);
		setMessageToAlter(rowData);
        setModalType('delete');
        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
		setMessageToAlter(null);
    };
	
	// delete message
	async function deleteMessage(){
		const result = DeleteService(`automatic-messages/${messageToAlter.id}`);
		if (result) {
			addAlert('Message deleted successfully', 'success', true);
			setAutomaticMessages( automaticMessages.filter((message) => message.id !== messageToAlter.id));
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while deleting the message', 'error', true);
		}
	}

	// update message
	async function updateMessage(automaticMessageObject){
		// add the message id to the object
		automaticMessageObject.message_id = messageToAlter.id;
		// if dayOfMonth or dayOfWeek is not set, make them null
		if (automaticMessageObject.frequency !== 'monthly') {
			automaticMessageObject.dayOfMonth = null;
		}
		if (automaticMessageObject.frequency !== 'weekly') {
			automaticMessageObject.dayOfWeek = null;
		}

		const result = await PutService(`automatic-message/${messageToAlter.id}`, automaticMessageObject);
		if (result) {
			addAlert('Message Updated', 'success', false);
			updateAutomaticMessagesState(automaticMessages.map((m) => m.id === messageToAlter.id ? automaticMessageObject : m));
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while updating the message', 'error', true);
		}
	}

	// add message
	async function AddMessage(automaticMessageObject){
		automaticMessageObject.location_id = location_id;
		const result = await PostService("automatic-messages", automaticMessageObject);
		if (result) {
			addAlert('Message Created', 'success', false);
			setAutomaticMessages((messages) => [...messages, automaticMessageObject]);
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while creating the message', 'error', true);
		}
	}


	// format the frequency of the message to be displayed in the grid
	const formatFrequency = (data) => {

		const timeTo12HourFormat = (time24) => {
			let [hour, minute] = time24.split(':');
			hour = parseInt(hour, 10);
			const ampm = hour >= 12 ? 'PM' : 'AM';
			hour = hour % 12;
			hour = hour ? hour : 12; // the hour '0' should be '12'
			return `${hour}:${minute} ${ampm}`;
		};
	
		const time = timeTo12HourFormat(data.timeToSend);
	
		if (data.frequency === 'daily') {
			return `Daily at ${time}`;
		} else if (data.frequency === 'weekly') {
			const dayOfWeek = data.dayOfWeek;
			return `Every ${dayOfWeek} at ${time}`;
		} else if (data.frequency === 'monthly') {
			const dayOfMonth = parseInt(data.dayOfMonth, 10);
			const suffix = (day) => {
				const j = day % 10;
				const k = day % 100;
				if (j === 1 && k !== 11) {
					return "st";
				}
				if (j === 2 && k !== 12) {
					return "nd";
				}
				if (j === 3 && k !== 13) {
					return "rd";
				}
				return "th";
			};
			return `The ${dayOfMonth}${suffix(dayOfMonth)} of the month at ${time}`;
		}
		return 'Invalid frequency';
	};
	
	

	// set up grid columns
	const gridStyle = { minHeight: 350, width: '100%'}
    const columns = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth:100 },
        { name: 'title', header: 'Title', defaultFlex: 1 },
        { name: 'message', header: 'Message Text', defaultFlex: 1 },
        { name: 'active', header: 'Status', defaultFlex: .5,
			render: ({ data }) => {
                return data.active ? "Active" : "Disabled";
            }
	 	},
		
		{name: 'frequency', header: 'Frequency', defaultFlex: 1.05,
			render: ({ data }) => {
				return formatFrequency(data);
			}
		},
		
        { name: 'recipients', header: 'Recipients', defaultFlex: .75,
			render: ({ data }) => {
                return data.recipients[0] === "checked_in" ? "All Checked In Trainees" : "Specific Trainees";
            }
		 },		
		 
        {
            header: 'Alter',
            render: ({ data }) => {
				return (
					<div style={{ display: 'flex', justifyContent:'center', gap: '10px' }}>
						<img src={PenIcon} className="pen-icon" alt="Edit" onClick={() => handleEdit(data)} />
						<img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDelete(data)} />
					</div>
				);
			},
            defaultFlex: .5,
        },
    ];
  return (
	<>
	<CardHeading 
		title='Automated Message Creator'
		description="This area is where you can create, edit, or remove 
		automated messages. This is the list of messages that are 
		currently created to be used for the automated message feature."
	/>
	<div className='card-list-wrapper'>
		<ReactDataGrid
			idProperty="id"
			style={gridStyle}
			columns={columns}
			dataSource={gridData}
		/>
	</div>
	<div className='add-record-button-wrapper'>
		<button type="button" className="button-blue add-record-button" onClick={() => handleAdd()}>
			Add New Message
		</button>
	</div>
	<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
		{modalType==='add' && <AutomaticMessageForm  onClose={handleCloseModal} messages={messages} trainees={trainees} onSuccessfulOperation={AddMessage}/>}
		{modalType==='edit' && <AutomaticMessageForm  onClose={handleCloseModal} messages={messages} trainees={trainees} messageToAlter={messageToAlter} onSuccessfulOperation={updateMessage}/>}
		{modalType==='delete' && <AutomaticMessageConfirmDelete onClose={handleCloseModal} message={messageToAlter} trainees={trainees} onSuccessfulOperation={deleteMessage}/>}
	</Modal>
	</>

  );
};

export default AutomaticMessagesList;
