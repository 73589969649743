const Table = ({ children, classNames, ...rest }) => {
    return (
        <table className={`table ${classNames ? classNames : ""}`} {...rest}>
            {children}
        </table>
    );
};

const TableRow = ({ children, classNames, ...rest }) => {
    return (
        <tr className={`table-row ${classNames ? classNames : ""}`} {...rest}>
            {children}
        </tr>
    );
};

const TableCell = ({ children, classNames, ...rest }) => {
    return (
        <td className={`table-cell ${classNames ? classNames : ""}`} {...rest}>
            {children}
        </td>
    );
};

export { Table, TableRow, TableCell };