import React, { useState, useEffect } from 'react';
import CardHeading from '../CardHeading';
import { GetAllLanguages, UpdateLanguage } from '../../apiCalls/LanguageCalls';
import { useAlert } from "../../context/AlertContext";

function LanguageSettings() {
	const [languages, setLanguages] = useState([]);
	const [keys, setKeys] = useState([]);
	const [languageData, setLanguageData] = useState({});
	const [newKey, setNewKey] = useState('');
	const [newValues, setNewValues] = useState({});
	const { addAlert } = useAlert();

	useEffect(() => {
		// Fetch all the languages when the component mounts
		async function loadLanguageData() {
		const result = await GetAllLanguages();
		if (result.success) {
			setLanguages(result.data);

			// Combine all keys from all languages
			const allKeys = new Set();
			result.data.forEach((language) => {
				Object.keys(language.data).forEach((key) => allKeys.add(key));
			});
			setKeys(Array.from(allKeys));

			// Create a lookup for language data
			const langData = {};
			result.data.forEach((language) => {
				langData[language.code] = language.data;
			});
			setLanguageData(langData);

			// Initialize newValues for adding new key-value pairs
			const initialNewValues = {};
			result.data.forEach((language) => {
				initialNewValues[language.code] = '';
			});
			setNewValues(initialNewValues);
		} else {
			setLanguages([]);
		}
		}

		loadLanguageData();
	}, []);

	const handleValueChange = (e, langCode, key) => {
		const newValue = e.target.value;
		setLanguageData((prevData) => ({
		...prevData,
		[langCode]: {
			...prevData[langCode],
			[key]: newValue,
		},
		}));
	};

//   const handleDeleteKey = (key) => {
//     // Remove the key from all languages
//     setLanguageData((prevData) => {
//       const newData = {};
//       Object.keys(prevData).forEach((langCode) => {
//         const { [key]: _, ...rest } = prevData[langCode];
//         newData[langCode] = rest;
//       });
//       return newData;
//     });
//     // Remove the key from the keys array
//     setKeys((prevKeys) => prevKeys.filter((k) => k !== key));
//   };
	const handleDeleteKey = (key) => {
		console.log('Before deletion:', languageData);
	
		setLanguageData((prevData) => {
		const newData = {};
	
		Object.entries(prevData).forEach(([langCode, langData]) => {
			const updatedLangData = { ...langData };
			delete updatedLangData[key];
			newData[langCode] = updatedLangData;
		});
	
		console.log('After deletion:', newData);
		return newData;
		});
	
		setKeys((prevKeys) => prevKeys.filter((k) => k !== key));
	};
  

	const handleNewValueChange = (e, langCode) => {
		const newValue = e.target.value;
		setNewValues((prevValues) => ({
			...prevValues,
			[langCode]: newValue,
		}));
	};

	const handleAddKeyValuePair = () => {
		if (!newKey) {
			addAlert("Please enter a new key.", "error", true);
			return;
		}
		if (keys.includes(newKey)) {
			addAlert("This key already exists.", "error", true);
			return;
		}
		// Add the new key to all languages
		setLanguageData((prevData) => {
			const newData = {};
			Object.keys(prevData).forEach((langCode) => {
				newData[langCode] = {
					...prevData[langCode],
					[newKey]: newValues[langCode] || '',
				};
			});
			return newData;
		});
		// Add the new key to the keys array
		setKeys((prevKeys) => [...prevKeys, newKey]);
		// Reset newKey and newValues
		setNewKey('');
		setNewValues((prevValues) => {
			const resetValues = {};
			Object.keys(prevValues).forEach((langCode) => {
				resetValues[langCode] = '';
			});
			return resetValues;
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// Prepare the updated languages data
			const updatedLanguages = languages.map((language) => ({
				...language,
				data: languageData[language.code],
			}));
		
			console.log('updatedLanguages', updatedLanguages);
		
			// Update each language separately
			for (const updatedLanguage of updatedLanguages) {
				console.log('updatedLanguage', updatedLanguage);
				const response = await UpdateLanguage(updatedLanguage.id, updatedLanguage.data);
				console.log('response', response);
				if (!response.success) {
				addAlert(`Failed to update language ${updatedLanguage.title}`, "error", true);
				return; // Exit if any update fails
				}
			}

			addAlert("Languages updated successfully", "success", false);
		} catch (error) {
			console.error(error);
			addAlert("An error occurred while updating languages", "error", true);
		}
	};
  

	return (
		<>
		<CardHeading
			title="Global Language Settings"
			description="Here you can edit the language used throughout the Tervuren Training Application"
		/>
		<form onSubmit={handleSubmit}>
			<table className="language-table">
			<thead>
				<tr>
				<th>Key</th>
				{languages.map((language) => (
					<th key={language.code}>{language.title}</th>
				))}
				<th></th>
				</tr>
			</thead>
			<tbody>
				{keys.map((key) => (
				<tr key={key}>
					<td>{key}</td>
					{languages.map((language) => (
					<td key={`${key}-${language.code}`}>
						<input
						type="text"
						value={languageData[language.code][key] || ''}
						onChange={(e) => handleValueChange(e, language.code, key)}
						/>
					</td>
					))}
					<td>
					<button type="button" className="button button-red" onClick={() => handleDeleteKey(key)}>
						Delete
					</button>
					</td>
				</tr>
				))}
				<tr>
					<td>
						<input
						type="text"
						placeholder="New Key"
						value={newKey}
						onChange={(e) => setNewKey(e.target.value)}
						/>
					</td>
					{languages.map((language) => (
						<td key={`new-${language.code}`}>
						<input
							type="text"
							placeholder={`Value in ${language.title}`}
							value={newValues[language.code] || ''}
							onChange={(e) => handleNewValueChange(e, language.code)}
						/>
						</td>
					))}
					<td>
						<button type="button" className="button button-blue bump-down" onClick={handleAddKeyValuePair}>
						+ Add
						</button>
					</td>
				</tr>
			</tbody>
			</table>
			<button type="submit" className="button-blue">Save Changes</button>
		</form>
		</>
	);
}

export default LanguageSettings;
