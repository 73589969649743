import React, { useState, useEffect, createContext, useCallback } from "react";
import { GetService } from "../services/GeneralServices";
import { PostService } from "../services/GeneralServices";
import { DeleteService } from "../services/GeneralServices";
import { PutService } from "../services/GeneralServices";
import { useSelector } from "react-redux";

const TraineeContext = createContext();

export function TraineeProvider({ children }) {
  const [trainees, setTrainees] = useState([]);
  // grab location id from redux
  const location_id = useSelector((state) => state.location.id);

  const updateTraineesState = useCallback((newTrainees) => {
    setTrainees(newTrainees);
  }, []);

  const addTraineeDB = async (newTrainee) => {
    try {
      let data = await PostService("trainee", newTrainee);
      setTrainees([...trainees, data]);
    } catch (error) {
      console.error("Error adding trainee:", error); // Debugging line
    }
  };

  const deleteTraineeDB = async (id) => {
    try {
      await DeleteService("trainee/id", id);
      setTrainees(trainees.filter((trainee) => trainee.id !== id));
    } catch (error) {
      console.error("Error deleting trainee:", error); // Debugging line
    }
  };

  const updateTraineeDB = async (id, updatedTrainee) => {
    try {
      let data = await PutService("trainee/id", id, updatedTrainee);
      setTrainees(
        trainees.map((trainee) => (trainee.id === id ? data : trainee))
      );
    } catch (error) {
      console.error("Error updating trainee:", error); // Debugging line
    }
  };

  useEffect(() => {
    const fetchTrainee = async () => {
      try {
        let fetchedTrainees = await GetService(
          `trainees/location/${location_id}`
        );
        // filter data and only list those with checked_in === true
        // fetchedTrainees = fetchedTrainees.filter(
        //   (trainee) => trainee.checked_in === true
        // );
        setTrainees(fetchedTrainees);
      } catch (error) {
        console.error("Error fetching trainees:", error); // Debugging line
      }
    };

    fetchTrainee();
  }, [location_id]);

  return (
    <TraineeContext.Provider
      value={{
        trainees,
        updateTraineesState,
        updateTraineeDB,
        deleteTraineeDB,
        addTraineeDB,
      }}
    >
      {children}
    </TraineeContext.Provider>
  );
}

export default TraineeContext;
