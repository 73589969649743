import AdminLayout from '../displays/AdminLayout';
import SplitCardCentered from '../../components/SplitCardCentered';
import CompetentTrainersList from '../../components/settings/CompetentTrainersList';
import LocationsList from '../../components/settings/LocationsList';
import AgreementForm from '../../components/settings/AgreementForm';
import RegionInfoForm from '../../components/settings/RegionInfoForm';
import { useSelector } from 'react-redux';

function RegionSettings(){
	const regionInfo = useSelector((state) => state.region);
    
    // an array of all the navigation options and it's related component to show on the right side
    const navigationItems = [
        { name: 'Region Information', component: <RegionInfoForm action='update' id={regionInfo.id} description="Here you can edit the region's general information including the logo you want to use and generic fall back image for the check in app location image"/> },
        { name: 'Locations List', component: <LocationsList scope='region' description="This is a list of all of the locations across the region. Here you can add, edit, or delete a location"/> },
        { name: 'Competent Trainers', component: <CompetentTrainersList scope='region' description="This is a list of all of the users across the region. Here you can add, edit, or delete a user"/> },
        { name: 'Agreement Form', component: <AgreementForm scope='region' description="Here you can edit the region's training agreement. This will also be the generic fall back agreements for all locations in the region if location does not have a specific training agreement."/> },
    ];

    // return veiw to be rendered
    return (
        <>
            <AdminLayout>
                <SplitCardCentered panelName='Region Settings' navigationItems={navigationItems} scope='region'/>
            </AdminLayout>
        </>
    )
}

export default RegionSettings