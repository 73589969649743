const TervurenLogo = (props) => (
  <>
    <img
      src="https://tervuren-company-objects.s3.us-east-2.amazonaws.com/Tervuren/application-files/tervuren-logo-white-lg.png"
      alt="Tervuren Logo"
      {...props}
    />
  </>
);

export default TervurenLogo;
