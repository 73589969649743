import { useState, createContext, useContext, useCallback } from "react";
import Alert from "../components/AlertComponent/Alert";

const AlertContext = createContext();

export function useAlert() {
  return useContext(AlertContext);
}

export default function AlertContextProvider({ children, timeout }) {
  const [alerts, setAlerts] = useState([]);

  // useCallback to save the same function reference in memory between first render and re-renders
  const addAlert = useCallback((message, type) => {
    setAlerts((prev) => [...prev, { message, type, id: Date.now() }]);
  }, []);

  const removeAlert = useCallback((id) => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  }, []);

  return (
    <AlertContext.Provider value={{ addAlert, removeAlert }}>
      {children}
      <div className="alert-container">
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            message={alert.message}
            type={alert.type}
            onDismiss={() => removeAlert(alert.id)}
            timeout={timeout || 3000}
          />
        ))}
      </div>
    </AlertContext.Provider>
  );
}
