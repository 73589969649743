import CardHeading from "../CardHeading";

export default function ConfirmDeleteMessage(props){
	return (	
		<>
		<CardHeading
			title={`Delete Message Confirmation`}
			description={`Are you sure you want to delete this Message? This action cannot be reversed.`}
		/>

		<div className="delete-item-text-wrapper">
			<h3><span>Title:</span> {props.message.title}</h3>
			<h3><span>Message:</span> {props.message.text}</h3>		
		</div>

		<div className="form-bottom-button-wrapper">
			<button className="button-blue" onClick={props.onSuccessfulOperation}>
				Delete Message
			</button>
		</div>
    </>
	);
}
