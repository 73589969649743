import {
  PostService,
  GetService,
  PutService,
  DeleteService,
} from "../services/GeneralServices";

export const GetLocation = async (location_id) => {
  try {
    const response = await GetService("location/id/" + location_id);
    if (response) {
      return {
        success: true,
        data: response, // * was info
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetAllLocations = async (type, id = null) => {
  // global is default
  let url = "";
  switch (type) {
    case "company":
      url = "locations/company/" + id;
      break;
    case "region":
      url = "locations/region/" + id;
      break;
    default:
      url = "locations";
      break;
  }
  try {
    const response = await GetService(url);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* add single location */
export const AddLocation = async (formData) => {
  try {
    const response = await PostService("location", formData);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* get all info for the location */
export const UpdateLocation = async (location_id, formData) => {
  try {
    const response = await PutService("location/id/" + location_id, formData);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const DeleteLocation = async (location_id) => {
  try {
    const response = await DeleteService("location/id/" + location_id);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/*
below expects the following:
{
    "image": "<base64-encoded-image>",
    "type": "<image-type>", png
    "file_name": "<file-name>", map|logo
    "relation": "<relation-type>", location|company|region
    "company_id": "<company-id>", needed for location and region
    "region_id": "<region-id>" need for location
}
*/
export const SetImage_Data = async (image_data) => {
  try {
    const response = await PutService("document/update-image", image_data);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};
