import {PostService, GetService, PutService, DeleteService} from '../services/GeneralServices';

export const GetLanguage = async (language_id) => {
    try {
        const response = await GetService('languages/id/' + language_id);
        if(response){
            return {
                success: true,
                data: response, // * was info
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
}

export const GetAllLanguages = async () => {     
    try {
        const response = await GetService('languages');
        if(response){
            return {
                success: true,
                data: response,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
}

export const AddLanguage = async (formData) => {
    try {
        const response = await PostService('languages', formData);
        if(response){
            return {
                success: true,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
}

export const UpdateLanguage = async (language_id, formData) => {
    try {
        const response = await PutService('language/id/'+language_id, formData);
        if(response){
            return {
                success: true,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
}

export const DeleteLanguage = async (language_id) => {
    try {
        const response = await DeleteService('languages/id/'+language_id);
        if(response){
            return {
                success: true,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
}