import RedShield from "../../assets/images/icon-shield-red.png";
import GreenShield from "../../assets/images/icon-shield-green.png";
import Checkin from "../../assets/images/check-in.png";
import Exit from "../../assets/images/exit.png";
import LoadingIcon from "../../assets/images/loading.svg";

const alertIcon = (type) => {
  switch (type) {
    case "error":
      return RedShield;
    case "success":
      return GreenShield;
    case "checkin":
      return Checkin;
    case "checkout":
      return Exit;
    case "loading":
      return LoadingIcon;
    default:
      return GreenShield;
  }
};

export default alertIcon;
