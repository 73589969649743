import { useContext } from "react";
import MessagingContext from "../../context/MessagingProvider";
import TraineeContext from "../../context/TraineeProvider";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { PostService } from "../../services/GeneralServices";
import Column from "../../components/Column";
import Row from "../../components/Row";
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import { useSelector } from "react-redux";
import CardHeading from "../CardHeading";
import { Table, TableRow, TableCell } from "../TableComponent";
import Select from "react-select";
import { useAlert } from "../../context/AlertContext";

const MessageSender = () => {
  const { messages } = useContext(MessagingContext);
  const { trainees } = useContext(TraineeContext);
  const [recipientType, setRecipientType] = useState("checkedIn");
  const [sending, setSending] = useState(false);

  const [traineeList, setTraineeList] = useState([]);
  const [selectedTrainee, setSelectedTrainee] = useState(trainees[0]);
  const [traineesForSelect, setTraineesForSelect] = useState([]);

  const location_id = useSelector((state) => state.location.id);
  const [selectedMessageText, setSelectedMessageText] = useState(messages[0]?.text || "");
  const { addAlert } = useAlert();
  

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      message: messages[0]?.id || "",
    },
  });

  useEffect(() => {
		let filteredTrainees = trainees.filter(
			(trainee) => trainee.checked_in === true
		);
		filteredTrainees = filteredTrainees.map(trainee => ({value: trainee.id, label: trainee.display_name}));
		filteredTrainees.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(filteredTrainees);
  }, [trainees]);

  useEffect(() => {
    if (messages && messages.length > 0) {
      setValue("message", messages[0]?.id || "");
    }
  }, [messages, setValue]);
  useEffect(() => {
    // Reset selectedTrainee to null when changing tabs or loading for the first time
    setSelectedTrainee(null);
  }, [recipientType]);

  const onSubmit = async (data) => {
    setSending(true);
    let recipients = data ? [trainees[0].phone] : [];
    let messageText = messages.find(
      (message) => message.id === data.message
    ).text;

    if (recipientType === "specific" && traineeList) {
      recipients = traineeList.map((trainee) => `+1${trainee.phone}`);
    } else if (recipientType === "checkedIn") {
      // check if they have checkin as true
      recipients = ["checked_in"];
    }

		const messagePayload = {
			message: messageText,
			recipients: recipients,
			location_id: location_id,
		};
		if (recipients.length > 0) {
			try {
				const send = await handleSendMessage(messagePayload);
				if (send) {
					addAlert('Message sent successfully', 'success', true);
				} else {
					addAlert('An error occurred while sending the message', 'error', true);
				}
			} catch (error) {
				addAlert('An error occurred while sending the message', 'error', true);
			}
		} else {
			addAlert('No recipients selected', 'error', true);
		}
	
		setSending(false);
		
  };

	const handleSendMessage = async (messagePayload) => {
		try {
			await PostService("sms/send-message", messagePayload);
			return true;
		} catch (error) {
			console.error('Error sending message:', error);
			return false;
		}
	};

	const handleAddTraineeList = () => {
		let traineeListIds = traineeList.map((trainee) => trainee.id);
		if (traineeListIds.includes(selectedTrainee.id)) {
			return;
		} 
		// selected trainee is an object with value and label
		const selectedTraineeFull = trainees.find(trainee => trainee.id === selectedTrainee.value);
		setTraineeList([...traineeList, selectedTraineeFull]);
		// Remove the selected trainee from the dropdown options
		let updatedTraineesForSelect = traineesForSelect.filter(trainee => trainee.value !== selectedTrainee.value);
		updatedTraineesForSelect.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(updatedTraineesForSelect);
		setSelectedTrainee(null); // Reset the selected trainee
	};

	const handleDeleteTraineeList = (id) => {
		let newTraineeList = traineeList.filter((trainee) => trainee.id !== id);
		setTraineeList(newTraineeList);
		// Add the removed trainee back to the dropdown options
		const removedTrainee = trainees.find(trainee => trainee.id === id);
		let updatedTraineesForSelect = [...traineesForSelect, { value: removedTrainee.id, label: removedTrainee.display_name }];
		updatedTraineesForSelect.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(updatedTraineesForSelect);
	};

	const handleRecipientTypeChange = (e) => {
		setRecipientType(e.target.value);
	};


  const handleTraineeChange = (selectedOption) => {
	// the selected option is value and label. we need to get the trainee object from the value(id)
	const selectedTrainee = trainees.find(trainee => trainee.id === selectedOption.value);	
	setSelectedTrainee({ value: selectedTrainee.id, label: selectedTrainee.display_name });

};

  useEffect(() => {
    if (messages && messages.length > 0) {
        setValue("message", messages[0]?.id || "");
        setSelectedMessageText(messages[0]?.text || "");
    }
}, [messages, setValue]);

  const handleMessageChange = (e) => {
    const selectedMessageId = e.target.value;
    const selectedMessage = messages.find((message) => message.id === selectedMessageId);
    setSelectedMessageText(selectedMessage?.text || "");
    setValue("message", selectedMessageId);
};

	return (
		<>
		<CardHeading 
			title="Message Sender1"
			description="You can only send messages to visitors that have checked in. Specific
			visitor sending is not allowed unless they have checked in. We have
			created specific list to make things easier for selecting opted in
			users. Filter based on how you would like to send messages then select
			the option you would like."
		/>
		
		<form onSubmit={handleSubmit(onSubmit)} className="message-sender">

			<div className="row">
				<div className={`half-width `}>
					<label htmlFor="message">Message:</label>
					<select {...register("message")} defaultValue={messages[0]?.text} onChange={handleMessageChange}>
					{messages &&
						messages.map((message) => (
						<option key={message.id} value={message.id}>
							{message.title}
						</option>
						))}
					</select>
				</div>
				<div className={`half-width selected-message`}>
					<label>Message Text:</label>
					<p className="selected-message-text">{selectedMessageText}</p>
				</div>
			</div>

			<Column classNames="gap-1">
				<label htmlFor="recipients">Recipient Type:</label>
				<Row classNames={"gap-1"}>
				<input
					type="radio"
					defaultChecked
					id="checkedIn"
					name="recipientType"
					value="checkedIn"
					onClick={handleRecipientTypeChange}
					{...register("recipientType", { required: true })}
				/>
				<label htmlFor="checkedIn">All Checked In Trainees</label>
				</Row>
				<Row classNames={"gap-1"}>
				<input
					type="radio"
					id="specific"
					name="recipientType"
					value="specific"
					onClick={handleRecipientTypeChange}
					{...register("recipientType", { required: true })}
				/>
				<label htmlFor="specific">Specific Trainee(s)</label>
				</Row>
				
			</Column>


			{recipientType === "specific" && (
				<div className="add-trainees-list">
					<h3>Choose Trainees:</h3>
					<div className="row">
						<div className="half-width">
							<label htmlFor="trainee">Add a Trainee To Message: </label>
							<Select
								name="trainee"
								value={selectedTrainee}
								onChange={handleTraineeChange}
								options={traineesForSelect}
								classNamePrefix={"react-select"}
								placeholder={`Select a trainee`}
							/>
						</div>
						<div className="quarter-width">
							<button
								className={`button-blue ${selectedTrainee ? "" : "disabled"}`}
								onClick={(e) => {
									e.preventDefault();
									handleAddTraineeList();
								}}
								disabled={!selectedTrainee}
							>
								Add Trainee
							</button>

						</div>
					</div>
					<h3>Selected Trainees:</h3>
					<Table classNames={"message-table"}>
						<thead>
							<TableRow classNames={"table-header-row"}>
								<TableCell>Name</TableCell>
								<TableCell>Phone</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</thead>
						<tbody>
							{traineeList.length > 0 ? (
								traineeList.map((trainee) => (
									<TableRow key={trainee.id} classNames={"table-row"}>
										<TableCell>{trainee.display_name}</TableCell>
										<TableCell>{trainee.phone}</TableCell>
										<TableCell>
											<img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDeleteTraineeList(trainee.id)} />
										</TableCell>
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={3}>No trainees selected yet</TableCell>
								</TableRow>
							)}
						</tbody>
					</Table>
				</div>
			)}
		<button
			disabled={recipientType === "specific" && traineeList.length === 0}
			type="submit"
			className={`button-blue ${
			recipientType === "specific" && traineeList.length === 0
				? "disabled"
				: ""
			} ${sending === true ? "disabled" : ""}`}
		>
			{sending === true ? "Sending..." : "Send Message"}
		</button>
		</form>
		</>
		
	);
};

export default MessageSender;
