import { PDFDocument } from "pdf-lib";

const ConvertMultiplePagePDFtoPDF = async (pdfs) => {
  // Create a new PDFDocument
  const mergedPdf = await PDFDocument.create();

  for (const pdf of pdfs) {
    // Load the PDF file
    const existingPdfBytes = await pdf.arrayBuffer();
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Copy pages from existing PDF to new PDF
    const copiedPages = await mergedPdf.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices()
    );
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await mergedPdf.save();
  return new Blob([pdfBytes], { type: "application/pdf" });
};

export default ConvertMultiplePagePDFtoPDF;
