import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import daysOfWeek from "../../helpers/daysOfWeek";
import numberDays from "../../helpers/numberDays";
import Row from "../Row";
import Column from "../Column";
import { useSelector } from "react-redux";
import { Table, TableRow, TableCell } from "../TableComponent";
import CardHeading from "../CardHeading";
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import Select from "react-select";


const AutomaticMessageForm = ({ messages, trainees, messageToAlter, onSuccessfulOperation }) => {
	const [recipientType, setRecipientType] = useState("checkedIn");
	const [traineeList, setTraineeList] = useState([]);
	const [selectedTrainee, setSelectedTrainee] = useState(null);
	const [frequency, setFrequency] = useState("daily");
	const location_id = useSelector((state) => state.location.id);
	const [formAction, setFormAction] = useState("create");
	const [selectedMessageText, setSelectedMessageText] = useState(messages[0]?.text || "");
	const [traineesForSelect, setTraineesForSelect] = useState([]);

	const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			message: messages[0]?.text || "",
		},
	});

	const[messageToUpdate, setMessageToUpdate] = useState({});

	useEffect(() => {
		setMessageToUpdate(messageToAlter);
		setFormAction(messageToAlter ? "update" : null);
	}, [messageToAlter]);

	useEffect(() => {
		if (formAction === "update" && messageToUpdate) {
			setValue("title", messageToUpdate.title);
			setValue("message", messageToUpdate.message);
			setValue("timeToSend", messageToUpdate.timeToSend);
			setValue("frequency", messageToUpdate.frequency);
			setValue("dayOfWeek", messageToUpdate.dayOfWeek);
			setValue("dayOfMonth", messageToUpdate.dayOfMonth);
			setValue("active", messageToUpdate.active);
			setValue("recipientType", messageToUpdate.recipientType);
			setFrequency(messageToUpdate.frequency);
			setRecipientType(messageToUpdate.recipientType);

			if (messageToUpdate.recipientType === "specific") {
				const specificTrainees = messageToUpdate.recipients.map(phone => trainees.find(trainee => trainee.phone === phone)).filter(trainee => trainee);
            	setTraineeList(specificTrainees);
			}else {
				setTraineeList([]);
			}
		}
	  }, [messageToUpdate, setValue, formAction, trainees]);


	useEffect(() => {
		const allTrainees = trainees.map(trainee => ({ value: trainee.id, label: trainee.display_name }));
		const filteredTrainees = allTrainees.filter(trainee => !traineeList.some(selected => selected.id === trainee.value));
		filteredTrainees.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(filteredTrainees);
	}, [trainees, traineeList]);
	

  

	const handleRecipientTypeChange = (e) => {
		setRecipientType(e.target.value);

		// Clear the trainee list when "All Checked In" is selected
		if (e.target.value === "checkedIn") {
			setTraineeList([]); 
		}
	};

	const handleAddTraineeList = () => {
		let traineeListIds = traineeList.map((trainee) => trainee.id);
		if (traineeListIds.includes(selectedTrainee.id)) {
			return;
		} 
		// selected trainee is an object with value and label
		const selectedTraineeFull = trainees.find(trainee => trainee.id === selectedTrainee.value);
		setTraineeList([...traineeList, selectedTraineeFull]);
		// Remove the selected trainee from the dropdown options
		let updatedTraineesForSelect = traineesForSelect.filter(trainee => trainee.value !== selectedTrainee.value);
		updatedTraineesForSelect.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(updatedTraineesForSelect);
		setSelectedTrainee(null); // Reset the selected trainee
	};

	const handleDeleteTraineeList = (id) => {
		let newTraineeList = traineeList.filter((trainee) => trainee.id !== id);
		setTraineeList(newTraineeList);
		// Add the removed trainee back to the dropdown options
		const removedTrainee = trainees.find(trainee => trainee.id === id);
		let updatedTraineesForSelect = [...traineesForSelect, { value: removedTrainee.id, label: removedTrainee.display_name }];
		updatedTraineesForSelect.sort((a, b) => a.label.localeCompare(b.label));
		setTraineesForSelect(updatedTraineesForSelect);
	};

	const handleTraineeChange = (selectedOption) => {
		// the selected option is value and label. we need to get the trainee object from the value(id)
		const selectedTrainee = trainees.find(trainee => trainee.id === selectedOption.value);	
		//setSelectedTrainee(selectedTrainee);
		setSelectedTrainee({ value: selectedTrainee.id, label: selectedTrainee.display_name });
	};


	const generateCronExpression = (frequency, time, dayOfWeek, dayOfMonth) => {
		const [hours, minutes] = time.split(":");

		const dayOfWeekMap = {
			'Sunday': 'SUN',
			'Monday': 'MON',
			'Tuesday': 'TUE',
			'Wednesday': 'WED',
			'Thursday': 'THU',
			'Friday': 'FRI',
			'Saturday': 'SAT'
		};

		const dayOfWeekAWS = dayOfWeekMap[dayOfWeek];

		switch (frequency) {
			case "daily":
				return `cron(${minutes} ${hours} * * ? *)`;
			case "weekly":
				return `cron(${minutes} ${hours} ? * ${dayOfWeekAWS} *)`;
			case "monthly":
				return `cron(${minutes} ${hours} ${dayOfMonth} * ? *)`;
			default:
				return "rate(1 hour)"; // Default fallback
		}
	};

	useEffect(() => {
		if (messages && messages.length > 0) {
			setSelectedMessageText(messages[0]?.text || "");
		}
	}, [messages, setValue]);

	const handleMessageChange = (e) => {
		const selectedMessageText = e.target.value;
		const selectedMessage = messages.find((message) => message.text === selectedMessageText);
		setSelectedMessageText(selectedMessage?.text || "");
	};

	const onSubmit = async (data) => {
		// Add traineeList to the form data
		data.traineeList = traineeList.map((trainee) => trainee.phone); 

		const cronExpression = generateCronExpression(
			data.frequency,
			data.timeToSend,
			data.dayOfWeek,
			data.dayOfMonth
		);

		let automaticMessageObject = {
			scheduleExpression: cronExpression,
			locationId: location_id,
			message: data.message,
			recipients:
				recipientType === "specific" ? data.traineeList : ["checked_in"],
			title: data.title,
			active: data.active || false,
			location_id: location_id,
			frequency: data.frequency,
			recipientType: recipientType,
			dayOfMonth: data.dayOfMonth,
			dayOfWeek: data.dayOfWeek,
			timeToSend: data.timeToSend,
		};
		
		// If the form action is to update, add the id to the object
		if(formAction === "update"){
			automaticMessageObject.id = messageToUpdate.id;
		}

		// handle submit in the parent component
		if(onSuccessfulOperation){
			onSuccessfulOperation(automaticMessageObject);
		}
	};
	
 

  return (
	<>
	<CardHeading 
		title={`${formAction === "update" ? "Edit" : "Create A New"} Automated Message`}
		description="Here you can set up messages to be sent either daily, 
		weekly, or monthly. You need to have a message saved in the message 
		library before you can use this function. Make sure to set the 
		message to active if you would like for it to be sending."
	/>
    <form onSubmit={handleSubmit(onSubmit)} className="automatic-message-creator">
		<div className="row message-active-row">
			<input type="checkbox" {...register("active")} />
			<label htmlFor="active">Active</label>
		</div>
		<div className="row">
			<div className={`half-width `}>
				<label htmlFor="title">Automated Message Title:</label>
				<input type="text" {...register("title", { required: true })} />
			</div>
			<div className={`half-width`}>
				<label htmlFor="message">Message Name:</label>
				<select
					{...register("message", { required: true })}
					defaultValue={"default"}
					onChange={handleMessageChange}
				>
					<option key="default" value="">
						Select a message
					</option>
					{messages &&
					messages.map((message) => (
						<option key={message.id} value={message.text}>
							{message.title}
						</option>
					))}
				</select>
			</div>
		</div>


		<div className="row">
			<div className={`half-width `}>
				<label htmlFor="timeToSend">Time to Send:</label>
				<input type="time" {...register("timeToSend", { required: true })} />
			</div>
			
			<div className={`half-width selected-message`}>
				<label>Message Text:</label>
				<p className="selected-message-text">{selectedMessageText}</p>
			</div>
		</div>

		<div className="row">
		<div className={`half-width `}>
			<label htmlFor="frequency">Frequency:</label>
			<select
				{...register("frequency", { required: true })}
				onChange={(e) => setFrequency(e.target.value)}
			>
				<option value="daily">Daily</option>
				<option value="weekly">Weekly</option>
				<option value="monthly">Monthly</option>
			</select>
			</div>
		</div>
		{/* conditionally show the week option */}
		{frequency === "weekly" && (
			<div className="row">
				<div className="half-width">
					<label htmlFor="dayOfWeek">Day of Week to Send:</label>
					<select {...register("dayOfWeek")}>
						{daysOfWeek.map((day) => (
							<option key={day} value={day}>
								{day}
							</option>
						))}
					</select>
				</div>
			</div>
		)}
		{/* conditionally show the month option */}
		{frequency === "monthly" && (
			<div className="row">
				<div className="half-width">
					<label htmlFor="dayOfMonth">Day of Month to Send:</label>
					<select {...register("dayOfMonth")}>
						{numberDays.map((day) => (
							<option key={day} value={day}>
								{day}
							</option>
						))}
					</select>
				</div>
			</div>
		)}
	  
	  
		<Column classNames="gap-1">
			<label htmlFor="recipients">Recipient Type:</label>
			<Row classNames={"gap-1"}>
			<input
				type="radio"
				defaultChecked
				id="checkedIn"
				name="recipientType"
				value="checkedIn"
				onClick={handleRecipientTypeChange}
				{...register("recipientType", { required: true })}
			/>
			<label htmlFor="checkedIn">All Checked In Trainees</label>
			</Row>
			<Row classNames={"gap-1"}>
			<input
				type="radio"
				id="specific"
				name="recipientType"
				value="specific"
				onClick={handleRecipientTypeChange}
				{...register("recipientType", { required: true })}
			/>
			<label htmlFor="specific">Specific Trainee(s)</label>
			</Row>
			
		</Column>


		{recipientType === "specific" && (
			<div className="add-trainees-list">
				<h4>* Trainees will only be texted if they are checked in to the location at the time the message is sent</h4>
				<h3>Choose Trainees:</h3>
				<div className="row">
					<div className="half-width">
						<label htmlFor="trainee">Add a Trainee To Message: </label>
						<Select
							name="trainee"
							value={selectedTrainee}
							onChange={handleTraineeChange}
							options={traineesForSelect}
							classNamePrefix={"react-select"}
							placeholder={`Select a trainee`}
						/>
					</div>
					<div className="quarter-width">
						<button className={`button-blue ${selectedTrainee ? "" : "disabled"}`} onClick={handleAddTraineeList} disabled={!selectedTrainee}>
							Add Trainee
						</button>
					</div>
				</div>
				<h3>Selected Trainees:</h3>
				<Table classNames={"message-table"}>
					<thead>
						<TableRow classNames={"table-header-row"}>
							<TableCell>Name</TableCell>
							<TableCell>Phone</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</thead>
					<tbody>
						{traineeList.length > 0 ? (
							traineeList.map((trainee) => (
								<TableRow key={trainee.id} classNames={"table-row"}>
									<TableCell>{trainee.display_name}</TableCell>
									<TableCell>{trainee.phone}</TableCell>
									<TableCell>
										<img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDeleteTraineeList(trainee.id)} />
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={3}>No trainees selected yet</TableCell>
							</TableRow>
						)}
					</tbody>
				</Table>
			</div>
		)}
		<button className="button-blue" type="submit">
				{formAction === "update" ? "Edit" : "Create"} Automated Message
		</button>
    </form>
	</>
  );
};

export default AutomaticMessageForm;
