/* 
    this is used for multiple instances, so there is a parameter named 'scope' passed in that 
    allows us to choose how to handle the data. This component is used for:
        - global settings
        - company settings
        - region settings
        - location settings

    passed in via scope: global | company | region | location
*/

import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import CardHeading from '../CardHeading';
import { GetAllLocations } from '../../apiCalls/LocationCalls';
import Modal from '../Modal';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import PenIcon from '../../assets/images/icon-pen-indigo.png';
import LocationInfoForm from './LocationInfoForm';
import ConfirmDelete from '../ConfirmDelete';
import { useSelector } from 'react-redux';
import { GetAllCompanies } from '../../apiCalls/CompanyCalls';
import { GetAllRegions } from '../../apiCalls/RegionCalls';


function LocationsList(props) {
  const scope = props.scope || 'company'; // decide which data to use
  const company = useSelector((state) => state.company);
  const region = useSelector((state) => state.region);
  const current_user = useSelector((state) => state.user);
  const current_user_role = current_user.role;
  const [gridData, setGridData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [modalType, setModalType] = useState('edit');
  const [companyChoices, setCompanyChoices] = useState([]); // for storing companies
  const [regionChoices, setRegionChoices] = useState([]); // for storing regions


  useEffect(() => {
    const fetchCompanies = async () => {
        const result = await GetAllCompanies('global', null);
        if (result.success) {
            setCompanyChoices(result.data);
        } else {
            console.log(result.message);
        }
    };
	const fetchRegions = async () => {
		const result = await GetAllRegions('global', null);
		if (result.success) {
			setRegionChoices(result.data);
		} else {
			console.log(result.message);
		}
	};
    fetchCompanies();
	fetchRegions();
}, []);

  function handleAdd() {
    setModalType('add');
    //setSelectedRowId(0); // flag for new record
    setIsModalOpen(true);
  }

  function handleEdit(rowData) {
    setSelectedRowId(rowData.id);
    setModalType('edit');
    setIsModalOpen(true);
  }

  function handleDelete(rowData) {
    setSelectedRowId(rowData.id);
    setModalType('delete');
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // load the locations information
  const loadData = async () => {
    let id = null;
    if (scope === 'region') {
      id = region.id;
    } else if (scope === 'company') {
      id = company.id;
    }
    const result = await GetAllLocations(scope, id);
	if (result.success) {
		const updatedData = result.data.map(location => {
			const companyName = companyChoices.find(company => company.id === location.company_id)?.name || 'N/A';
			const regionName = regionChoices.find(region => region.id === location.region_id)?.name || 'N/A';
			return {
				...location,
				company_name: companyName,
				region_name: regionName
			};
		});
		
		setGridData(updatedData);
	}else {
      console.log(result.message);
    }
  };

  // get the data for the grid and set it
  useEffect(() => {
    loadData();
  }, [scope, companyChoices, regionChoices]);

  const gridStyle = { minHeight: 350 };
  // Define a function to render cell content, showing 'N/A' for empty/null values
  const renderCellContent = (value) => value || 'N/A';

  // Dynamic columns based on scope
  const dynamicColumns = () => {
    let extraColumns = [];
    if (scope === 'global') {
      extraColumns = [
        {
          name: 'company_name',
          header: 'Company Name',
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
        {
          name: 'region_name',
          header: 'Region Name',
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
      ];
    } else if (scope === 'company') {
      extraColumns = [
        {
          name: 'region_name',
          header: 'Region Name',
          defaultFlex: 2,
          render: ({ value }) => renderCellContent(value),
        },
      ];
    }
    return extraColumns;
  };

  // normal cloumns
  const columns = [
    {
      name: 'id',
      header: 'Id',
      defaultVisible: false,
      type: 'number',
      defaultWidth: 100,
    },
    { name: 'name', header: 'Location Name', defaultFlex: 2 },
    { name: 'address', header: 'Address', defaultFlex: 2 },
    { name: 'state', header: 'State', defaultFlex: 1 },
    { name: 'zip', header: 'Zip Code', defaultFlex: 1 },
    { name: 'email', header: 'Email', defaultFlex: 2 },
    { name: 'phone', header: 'Phone', defaultFlex: 2 },
    // add extra columns based on scope
    ...dynamicColumns(),
    {
      header: 'Alter',
      render: ({ data }) => (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <img
            src={PenIcon}
            className="pen-icon"
            alt="Edit"
            onClick={() => handleEdit(data)}
          />

		  {current_user_role > 4 && 
		  	<img 
				src={DeleteIcon} 
				className='delete-icon' 
				alt="delete" 
				onClick={() => handleDelete(data)} 
			/>
		  }
        </div>
      ),
      defaultFlex: 1,
    },
  ];

  // return veiw to be rendered
  return (
    <>
      <CardHeading
        title="Locations List"
        description={props.description || ''}
      />
      <div className="card-list-wrapper">
        <ReactDataGrid
          idProperty="id"
          style={gridStyle}
          columns={columns}
          dataSource={gridData}
        />
      </div>
	  {current_user_role > 4 && (
		<div className="add-record-button-wrapper">
			<button
			type="button"
			className="button-blue add-record-button"
			onClick={() => handleAdd()}
			>
			Add Location
			</button>
		</div>
	  )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {modalType === 'add' && (
          <LocationInfoForm
            action="add"
            onClose={handleCloseModal}
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
        {modalType === 'edit' && (
          <LocationInfoForm
            action="update"
            onClose={handleCloseModal}
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
        {modalType === 'delete' && (
          <ConfirmDelete
            scope="location"
            onClose={handleCloseModal}
            id={selectedRowId}
            onSuccessfulOperation={loadData}
          />
        )}
      </Modal>
    </>
  );
}

export default LocationsList;
