import React from 'react';
import { useSelector } from 'react-redux';

export default function CompanyLogo(props) {
	const scope = props.scope;
    const defaultLogoURL =
    'https://tervuren-company-objects.s3.us-east-2.amazonaws.com/Tervuren/images/logo';
    const companyLogoURL = useSelector((state) => state.company.logo);
    const regionLogoURL = useSelector((state) => state.region.logo);
    const locationLogoURL = useSelector((state) => state.location.logo);

	let logoToUse;

    if (scope) {
        switch (scope) {
            case 'global':
                logoToUse = defaultLogoURL;
                break;
			case 'company':
				logoToUse = companyLogoURL;
				break;
            case 'region':
                logoToUse = regionLogoURL;
                break;
            case 'location':
                logoToUse = locationLogoURL;
                break;
            default:
                logoToUse = defaultLogoURL;
        }
    } else {
		// Determine which logo to use - the company logo if available, otherwise the default
        logoToUse = locationLogoURL || regionLogoURL || companyLogoURL || defaultLogoURL;
    }

  return (
    <div>
      <img src={logoToUse} alt="Company Logo" {...props} />
    </div>
  );
}
