import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import { useSelector } from 'react-redux';


/*
    check if our access token exists in our local storage.
    If the token exist, redirect the user to the route the user wants to access,
    if not redirect the user to a route that we are going to specify.
*/

export const Guard = ({ token, routeRedirect }) => {
  const location = useLocation();

  return localStorage.getItem(token)
    ? <Outlet/>
    : <Navigate to={routeRedirect} replace state={{ from: location }} />;
};


export const RoleGuard = ({ children, requiredRole }) => {
    const userRole = useSelector(state => state.user.role);

    if (userRole >= requiredRole) {
        return children;
    } else {
        return <Navigate to="/dashboard" replace />;
    }
};