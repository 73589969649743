import '../assets/style/components/loader.scss';
import shield from '../assets/images/tervuren-shield-white.png';

export default function Loader() {
    return (
        <div className='loader-overlay'>
            <div className="inner-wrapper">
                <div className="spinner-wrapper">
                    <div className="spinner">
                        <div className="spinner-line">
                            <div className="spinner-line-cog">
                                <div className="spinner-line-cog-inner spinner-line-cog-inner-left"></div>
                            </div>
                            <div className="spinner-line-ticker">
                                <div className="spinner-line-cog-inner spinner-line-cog-inner-center"></div>
                            </div>
                            <div className="spinner-line-cog">
                                <div className="spinner-line-cog-inner spinner-line-cog-inner-right"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loader-center">
                    <img src={shield} alt="Tervuren Shield"></img>
                </div>
            </div>
        </div>
    )
}