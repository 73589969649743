import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetAllLocations } from "../../apiCalls/LocationCalls";
import { GetCompany } from "../../apiCalls/CompanyCalls";
import { setAllCompanyInfo } from "../../redux/reducers/CompanyReducer";
import { setAllLocationInfo } from "../../redux/reducers/LocationReducer";
import { setAllUserInfo } from "../../redux/reducers/UserReducer";
import { setScreen } from "../../redux/reducers/PageReducer";
import { useNavigate } from "react-router-dom";

const LocationSelection = () => {
  const [locations, setLocations] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useNavigate();

  const locationIds = useMemo(() => {
    return user?.location_id?.split(",") || [];
  }, [user.location_id]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        // Fetch all locations for the company
        const res = await GetAllLocations("company", `${user.company_id}`);
        if (res && res.data) {
          // Assuming res.data is an array of locations
          const allLocations = res.data;
          // Filter locations based on user's location IDs
          const filteredLocations = allLocations.filter((location) =>
            locationIds.includes(location.id.toString())
          );
          setLocations(filteredLocations);
        }
      } catch (error) {
        console.error("Error loading locations:", error);
      }
    };

    if (locationIds.length) {
      fetchLocations();
    }
  }, [locationIds, user.company_id]);

  const onLocationSelect = (location) => async () => {
    try {
      dispatch(setAllLocationInfo(location));
      const companyRes = await GetCompany(`id/${location.company_id}`);
      if (companyRes && companyRes.data) {
        // Update user with selected location ID
        const updatedUser = { ...user, location_id: location.id.toString() };
        console.log(updatedUser);

        dispatch(setAllCompanyInfo(companyRes.data));
        dispatch(setAllLocationInfo(location));
        dispatch(setScreen("training-lobby"));
        dispatch(setAllUserInfo(updatedUser));
        history("/training-lobby");
      }
    } catch (error) {
      console.error("Error loading company:", error);
    }
  };

  return (
    <div className="bg-topo-light fullscreen">
      <div className="location-selection">
        <h2>Location Selection</h2>
        <ul>
          {locations.map((location) => (
            <li key={location.id}>
              <button
                className="button button-blue"
                onClick={onLocationSelect(location)}
              >
                {location.name} - {location.address}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LocationSelection;
