import React, { useEffect } from "react";
import alertIcon from "./alertIcon";

export default function Alert({ message, type, onDismiss, timeout }) {
  // types include loading, error, success, checkin, checkout
  const icon = alertIcon(type);

  useEffect(() => {
    // Automatically dismiss the alert after 3 seconds
    const timer = setTimeout(() => {
      onDismiss();
    }, timeout || 3000);

    return () => clearTimeout(timer);
  }, [onDismiss]);

  return (
    <div className={`alert alert-${type}`}>
      <img src={icon} alt="Alert Icon" />
      {message}
    </div>
  );
}
