import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import iconClose from "../assets/images/icon-close-white.png";

function Dropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // return veiw to be rendered
  return (
    <div className="dropdown-container">
      <div
        className="dropdown-toggle"
        ref={toggleRef}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="letters-circle">{props.initials}</p>
        {/* <span>{isOpen ? '▲' : '▼'}</span> */}
        {isOpen ? (
          <div className="arrow up"></div>
        ) : (
          <div className="arrow down"></div>
        )}
      </div>

      {isOpen && (
        <div
          className={`dropdown-menu ${isOpen ? "open" : ""}`}
          ref={dropdownRef}
        >
          <div className="menu-top">
            <p className="letters-circle">{props.initials}</p>
            <p>{props.name}</p>
            <img
              src={iconClose}
              className="dropdown-close"
              alt="drop down icon"
              onClick={() => setIsOpen(false)}
            ></img>
          </div>
          <ul className={isOpen ? "open" : ""}>
            <li onClick={() => setIsOpen(false)}>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <Link to="/trainees">Trainees List</Link>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <Link to="/training-records">Training Records</Link>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <Link to="/checkin-records">Visitor Records</Link>
            </li>
            <li onClick={() => setIsOpen(false)}>
              <Link to="/my-account">My Profile</Link>
            </li>
          </ul>
          <div className="menu-bottom">
            <Link to="/logout">Logout</Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
