import React, { useState, useEffect } from 'react';
import CardHeading from './CardHeading';
import { useAlert } from '../context/AlertContext';
import { GetTrainee, DeleteTrainee } from '../apiCalls/TraineeCalls';
import { GetLocation } from '../apiCalls/LocationCalls';


export default function ConfirmDeleteTrainee(props) {
	const trainee_id = props.id || 0; // id to use if for a specific component
	const { addAlert } = useAlert();
	const [traineeInfo, setTraineeInfo] = useState({});
	const [locationInfo, setLocationInfo] = useState({});

	useEffect(() => {
		async function fetchTrainee() {
			const result = await GetTrainee(trainee_id);
			if (result.success) {
				setTraineeInfo(result.data);
			}else{
				addAlert('An error occurred while fetching trainee', 'error', true);
			}
		}
		fetchTrainee();
	}, [trainee_id, addAlert]);

	useEffect(() => {
		async function fetchTraineeLocation() {
			const result = await GetLocation(traineeInfo.location_id);
			if (result.success) {
				setLocationInfo(result.data);
			}else{
				addAlert('An error occurred while fetching trainee location', 'error', true);
			}
		}
		fetchTraineeLocation();
	}, [traineeInfo, addAlert]);

	function deleteTrainee() {
		DeleteTrainee(trainee_id).then((response) => {
			if (response.success) {
				addAlert('Trainee deleted successfully', 'success', true);
				if(props.onSuccessfulOperation){
					props.onSuccessfulOperation();
				}
				props.onClose();
			}else{
				addAlert('An error occurred while deleting trainee', 'error', true);
			}
		});
	}

  return (
    <>
		<CardHeading
			title={`Delete Trainee Confirmation`}
			description={`Are you sure you want to delete this Trainee? This action cannot be reversed.`}
		/>

		<div className="delete-item-text-wrapper">
			<h3><span>Trainee:</span> {traineeInfo.first_name} {traineeInfo.last_name}</h3>
			<h3><span>Company:</span> {traineeInfo.display_company}</h3>
			<h3><span>Training Location:</span> {locationInfo.name}</h3>			
		</div>

		<div className="form-bottom-button-wrapper">
			<button className="button-blue" onClick={deleteTrainee}>
				Delete Trainee
			</button>
		</div>
    </>
  );
}
