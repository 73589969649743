import React, { useState, useEffect } from 'react';
import { GetAllCheckIns } from '../../apiCalls/CheckInCalls';
import { GetAllTrainees } from '../../apiCalls/TraineeCalls';
import { useSelector } from 'react-redux';
import RecordsLayout from '../displays/RecordsLayout';
import Modal from '../../components/Modal';
import DeleteIcon from '../../assets/images/icon-x-indigo.png';
import ConfirmDeleteVisitorRecord from '../../components/ConfirmDeleteVisitorRecord';

const CheckInRecords = () => {
    const [checkins, setCheckins] = useState([]);
    const [trainees, setTrainees] = useState([]);
    const [enrichedCheckins, setEnrichedCheckins] = useState([]);
    const [checkinCheckoutPairs, setCheckinCheckoutPairs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [modalType, setModalType] = useState('edit');

    const current_user = useSelector((state) => state.user);
    const current_user_role = current_user.role;
    const [entityType, setEntityType] = useState('location');	
    const [entityId, setEntityId] = useState(null);
	

    const handleDelete = (rowData) => {
        setSelectedRowData(rowData);
        setModalType('delete');
        setIsModalOpen(true);
    };

    const handleCloseModal = () => setIsModalOpen(false);

    const loadData = async (newEntityType = entityType, newEntityId = entityId) => {
        const checkinsResult = await GetAllCheckIns(newEntityType, newEntityId);
        const traineesResult = await GetAllTrainees(newEntityType, newEntityId);
        if (checkinsResult.success) setCheckins(checkinsResult.data);
        if (traineesResult.success) setTrainees(traineesResult.data);
    };

    useEffect(() => {
        const userRole = parseInt(current_user.role);
        let newEntityType = 'location';
        let newEntityId = null;
    
        if (userRole === 1 || userRole === 2) {
            newEntityId = current_user.location_id;
        } else if (userRole === 3) {
            newEntityType = 'region';
            newEntityId = current_user.region_id;
        } else if (userRole === 4) {
            newEntityType = 'company';
            newEntityId = current_user.company_id;
        } else if (userRole === 5) {
            newEntityType = '';
        }
    
        setEntityType(newEntityType);
        setEntityId(newEntityId);
        
        loadData(newEntityType, newEntityId);
    }, [current_user]);

    useEffect(() => {
        const groupedByTrainee = checkins.reduce((acc, record) => {
            if (!acc[record.trainee_id]) acc[record.trainee_id] = [];
            acc[record.trainee_id].push(record);
            return acc;
        }, {});

        for (const traineeId in groupedByTrainee) {
            groupedByTrainee[traineeId].sort((a, b) => a.timestamp - b.timestamp);
        }

        const checkinCheckoutPairs = [];
        const ongoingCheckins = [];

        for (const traineeId in groupedByTrainee) {
            const records = groupedByTrainee[traineeId];
            let checkinRecord = null;

            for (const record of records) {
                if (record.type === 1) {
                    if (checkinRecord) {
                        ongoingCheckins.push({
                            trainee_id: traineeId,
                            checkin_timestamp: checkinRecord.timestamp,
                            checkin_id: checkinRecord.id,
                        });
                    }
                    checkinRecord = record;
                } else if (checkinRecord) {
                    const checkoutRecord = record;
                    checkinCheckoutPairs.push({
                        trainee_id: traineeId,
                        checkin_timestamp: checkinRecord.timestamp,
                        checkout_timestamp: checkoutRecord.timestamp,
                        checkin_id: checkinRecord.id,
                        checkout_id: checkoutRecord.id,
                    });
                    checkinRecord = null;
                }
            }

            if (checkinRecord) {
                ongoingCheckins.push({
                    trainee_id: traineeId,
                    checkin_timestamp: checkinRecord.timestamp,
                    checkin_id: checkinRecord.id,
                });
            }
        }

        const combinedRecords = [...checkinCheckoutPairs, ...ongoingCheckins];
        combinedRecords.sort((a, b) => b.checkin_timestamp - a.checkin_timestamp);

        setCheckinCheckoutPairs(combinedRecords);
    }, [checkins]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}/${year}`;
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes} ${ampm}`;
    };

    useEffect(() => {
        if (checkinCheckoutPairs.length > 0 && trainees.length > 0) {
            const newEnrichedCheckins = checkinCheckoutPairs.map(checkin => {
                const trainee = trainees.find(t => t.id === checkin.trainee_id);
                const isOnSite = !checkin.checkout_timestamp;
                return {
                    ...checkin,
                    trainee_first_name: trainee?.first_name || '',
                    trainee_last_name: trainee?.last_name || '',
                    trainee_phone: trainee?.phone || '',
                    trainee_email: trainee?.email || '',
                    trainee_visitor_type: trainee?.visitor_type || '',
                    trainee_company_name: trainee?.company || '',
                    checkin_date: formatDate(checkin.checkin_timestamp),
                    checkin_time: formatTime(checkin.checkin_timestamp),
                    checkout_time: isOnSite ? 'N/A' : formatTime(checkin.checkout_timestamp),
                    status: isOnSite ? 'On Site' : 'Off Site',
                };
            });
            setEnrichedCheckins(newEnrichedCheckins);
        }
    }, [checkinCheckoutPairs, trainees]);

    const gridStyle = { minHeight: 550, width: '100%' };
    const defaultColumns = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth: 100 },
        { name: 'trainee_id', header: 'Trainee ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
        { name: 'trainee_first_name', header: 'First Name', defaultFlex: 2 },
        { name: 'trainee_last_name', header: 'Last Name', defaultFlex: 2 },
        { name: 'trainee_phone', header: 'Phone', defaultFlex: 2 },
        { name: 'trainee_email', header: 'Email', defaultFlex: 2 },
        { name: 'checkin_date', header: 'Date', defaultFlex: 2 },
        { name: 'checkin_time', header: 'Check-In', defaultFlex: 2 },
        { name: 'checkout_time', header: 'Check-Out', defaultFlex: 2 },
        { name: 'trainee_company_name', header: 'Company Name', defaultFlex: 2 },
        { name: 'trainee_visitor_type', header: 'Visitor Type', defaultFlex: 2 },
        { name: 'checkin_id', header: 'Check-In ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
        { name: 'checkout_id', header: 'Check-Out ID', defaultVisible: false, type: 'number', defaultFlex: 2 },
        { name: 'status', header: 'Status', defaultFlex: 2, render: ({ data }) => data.status },
        {
            name: 'alter', header: 'Alter', defaultFlex: 1,
            render: ({ data }) => {
                const canDelete = current_user_role >= 2;
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                        {canDelete && (
                            <img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDelete(data)} />
                        )}
                    </div>
                );
            },
           
        },
    ];
/* -----------------------------------------------------
	* [start] handle column order change
	*/

	
	const [gridKey, setGridKey] = useState(0); // this is used to manually update/rerender the data grid when needed
	const [columns, setColumns] = useState(defaultColumns);
	const localStorageKey = `checkinListColumns_${current_user.id}`;
	const [columnsSaved, setColumnsSaved] = useState(false);
	
	useEffect(() => {
		const savedColumns = localStorage.getItem(localStorageKey);
		if (savedColumns) {
			const parsedColumns = JSON.parse(savedColumns);
			const columnsWithRenderFunctions = parsedColumns.map(col => {
				const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
				return defaultCol ? { ...col, render: defaultCol.render } : col;
			});
			setColumns(columnsWithRenderFunctions);
			setColumnsSaved(true);
		}
	}, []);
	
	const handleColumnChange = (newColumns) => {
		const columnsToSave = newColumns.map(col => {
			const defaultCol = defaultColumns.find(defaultCol => defaultCol.name === col.name);
			return defaultCol ? { ...col, render: defaultCol.render } : col;
		});
		setColumns(columnsToSave);
		localStorage.setItem(localStorageKey, JSON.stringify(columnsToSave));
		setColumnsSaved(true);
	};
	

	const resetColumns = () => {
		localStorage.removeItem(localStorageKey);
		setColumns(defaultColumns);
		setColumnsSaved(false);
		setGridKey(prevKey => prevKey + 1); // Update the key to force remount
	};


	/*
	* [end] handle column order change
	* -----------------------------------------------------
	*/
    return (
        <>
            <RecordsLayout
				key={gridKey}
                title='Visitor Records'
                addRecordType='CheckIn'
                style={gridStyle}
                columns={columns}
                dataSource={enrichedCheckins}
                exportType='csv'
                exportFileName='Visitor-Records.csv'
                recordType='Visitor'
                onSuccessfulOperation={() => loadData(entityType, entityId)}
				onColumnChange={handleColumnChange}
				onResetColumns={resetColumns}
				columnsSaved={columnsSaved}
            />
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                {modalType === 'delete' && <ConfirmDeleteVisitorRecord onClose={handleCloseModal} trainingData={selectedRowData} onSuccessfulOperation={loadData} />}
            </Modal>
        </>
    );
};

export default CheckInRecords;
