import React, { useState } from 'react';
import CardNavigation from './CardNavigation';
import CompanyLogo from './CompanyLogo'
import '../assets/style/components/split-card.scss'
import {Link} from 'react-router-dom';

function SplitCardCentered({ panelName, navigationItems, scope }) {
  const [activeComponent, setActiveComponent] = useState(navigationItems[0].component);

  return (
    <div className='split-card'>

        <div className='split-card-left'>
            <CompanyLogo scope={scope}/>
            <h3>{panelName}</h3>
            <CardNavigation 
                navigationItems={navigationItems} 
                setActiveComponent={setActiveComponent} 
                activeComponent={activeComponent} 
            />
        </div>

        <div className='split-card-right'>
            <Link to="/dashboard" className='back-to-dash'>Back To Dashboard</Link>
            {activeComponent}
        </div>

    </div>
  );
}

export default SplitCardCentered;
