import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RoleGuard } from "./Guards"; // Removed Guard since it's not used

// Auth routes
import Logout from "./containers/auth/Logout";

// Other routes
import Dashboard from "./containers/pages/Dashboard";
import TrainingRecords from "./containers/pages/TrainingRecords";
import CheckInRecords from "./containers/pages/CheckInRecords";
import LocationSettings from "./containers/pages/LocationSettings";
import RegionSettings from "./containers/pages/RegionSettings";
import CompanySettings from "./containers/pages/CompanySettings";
import GlobalSettings from "./containers/pages/GlobalSettings";
import MyAccount from "./containers/pages/MyAccount";
import Messaging from "./containers/pages/Messaging";
import TraineesList from "./containers/pages/TraineesList";
import LocationSelection from "./containers/pages/LocationSelection";
import { setAllUserInfo } from "./redux/reducers/UserReducer";
import { MessagingProvider } from "./context/MessagingProvider";
import { LoginAction } from "./redux/actions/AuthActions";
import { setAllLocationInfo } from "./redux/reducers/LocationReducer";
import { setAllCompanyInfo } from "./redux/reducers/CompanyReducer";
import { setAllRegionInfo } from "./redux/reducers/RegionReducer";
import { GetLocation } from "./apiCalls/LocationCalls";
import { GetCompany } from "./apiCalls/CompanyCalls";
import { GetRegion } from "./apiCalls/RegionCalls";
import { resetGlobalState } from "./redux/reducers/GlobalReducer";
import { resetCompanyState } from "./redux/reducers/CompanyReducer";
import { resetRegionState } from "./redux/reducers/RegionReducer";
import { resetLocationState } from "./redux/reducers/LocationReducer";
import { resetUserState } from "./redux/reducers/UserReducer";
import { setScreen } from "./redux/reducers/PageReducer";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import outputs from "./amplify_outputs.json";
// Removed setUserState since it's not being used
import { fetchUserAttributes } from "@aws-amplify/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure(outputs);

function AuthenticatedRoutes({ user, signOut }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      fetchUserAttributes().then(async (attributes) => {
        console.log("User attributes:");
        console.log(attributes);
        const formatUser = Object.entries(attributes).reduce(
          (acc, [key, value]) => {
            if (key.startsWith("custom:")) {
              acc[key.replace("custom:", "")] = value;
            } else {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        dispatch(resetGlobalState());
        dispatch(resetCompanyState());
        dispatch(resetRegionState());
        dispatch(resetLocationState());
        dispatch(resetUserState());

        const [locationInfo, companyInfo, regionInfo] = await Promise.all([
          GetLocation(formatUser.location_id),
          GetCompany(formatUser.company_id),
          GetRegion(formatUser.region_id),
        ]);

        dispatch(setAllUserInfo(formatUser));
        dispatch(setAllLocationInfo(locationInfo.data));
        dispatch(setAllCompanyInfo(companyInfo.data));
        dispatch(setAllRegionInfo(regionInfo.data));
        if (formatUser.location_id.includes(",")) {
          // go to location selection
          setScreen("location-selection");
          setTimeout(() => {
            history("/location-selection");
          }, 1);
          return;
        }
      });
    }
  }, [user]);

  return (
    <Routes>
      <Route path="/logout" element={<Logout signOut={signOut} />} />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/training-records" element={<TrainingRecords />} />
      <Route path="/checkin-records" element={<CheckInRecords />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/trainees" element={<TraineesList />} />
      <Route path="/location-selection" element={<LocationSelection />} />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/settings/location"
        element={
          <RoleGuard requiredRole={2}>
            <LocationSettings />
          </RoleGuard>
        }
      />
      <Route
        path="/settings/messaging"
        element={
          <RoleGuard requiredRole={2}>
            <MessagingProvider>
              <Messaging />
            </MessagingProvider>
          </RoleGuard>
        }
      />
      <Route
        path="/settings/region"
        element={
          <RoleGuard requiredRole={3}>
            <RegionSettings />
          </RoleGuard>
        }
      />
      <Route
        path="/settings/company"
        element={
          <RoleGuard requiredRole={4}>
            <CompanySettings />
          </RoleGuard>
        }
      />
      <Route
        path="/settings/global"
        element={
          <RoleGuard requiredRole={5}>
            <GlobalSettings />
          </RoleGuard>
        }
      />
      <Route path="/*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}

export default function AppRoutes() {
  return (
    <Authenticator hideSignUp className="body-decorator">
      {({ signOut, user }) => (
        <AuthenticatedRoutes user={user} signOut={signOut} />
      )}
    </Authenticator>
  );
}
