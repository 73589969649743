

export function ViewAndDownloadPdf(pdfUrl) {
    const url = pdfUrl.data;
    const getFileNameFromUrl = (url) => {
        return url.substring(url.lastIndexOf('/') + 1);
    }
    const fileName = getFileNameFromUrl(url);    

    const handleDownload = async () => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName); // or any other name
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading the PDF:', error);
        }
    };

    return (
        <div>
            <iframe title="pdf" src={url} width="100%" height="500px" />
            <button className="button-blue" onClick={handleDownload}>Download PDF</button>
        </div>
    )
}