import AdminLayout from '../displays/AdminLayout';
import SplitCardCentered from '../../components/SplitCardCentered';
import CompetentTrainersList from '../../components/settings/CompetentTrainersList';
import AgreementForm from '../../components/settings/AgreementForm';
import LocationInfoForm from '../../components/settings/LocationInfoForm';
import { useSelector } from 'react-redux';

function LocationSettings(){
    const locationInfo = useSelector((state) => state.location);
    // an array of all the navigation options and it's related component to show on the right side
    const navigationItems = [
        { name: 'Location Information', component: <LocationInfoForm id={locationInfo.id} action='update' description="Here you can edit the location's general information including the logo and location image you want to use."/> },
        { name: 'Competent Trainers', component: <CompetentTrainersList scope='location' description="This is a list of all of the users across the location. Here you can add, edit, or delete a user"/> },
        { name: 'Agreement Form', component: <AgreementForm scope='location' description="Here you can edit the location's training agreement."/> },
    ];

    // return veiw to be rendered
    return (
        <>
            <AdminLayout>
                <SplitCardCentered panelName='Location Settings' navigationItems={navigationItems} scope='location'/>
            </AdminLayout>
        </>
    )
}

export default LocationSettings