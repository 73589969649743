import AdminLayout from '../displays/AdminLayout';
import SplitCardCentered from '../../components/SplitCardCentered';
import CompetentTrainersList from '../../components/settings/CompetentTrainersList';
import RegionsList from '../../components/settings/RegionsList';
import LocationsList from '../../components/settings/LocationsList';
import AgreementForm from '../../components/settings/AgreementForm';
import CompanyInfoForm from '../../components/settings/CompanyInfoForm';
import { useSelector } from 'react-redux';
function CompanySettings(){
    const currentUser = useSelector((state) => state.user);

    // an array of all the navigation options and it's related component to show on the right side
    const navigationItems = [
        { name: 'Company Information', component: <CompanyInfoForm action='update' id={currentUser.company_id} description="Here you can edit the company's general information including the logo you want to use and generic fall back image for the check in app location image"/> },
        { name: 'Regions List', component: <RegionsList scope='company' description="This is a list of all of the regions across the company. Here you can add, edit, or delete a region"/> },
        { name: 'Locations List', component: <LocationsList scope='company' description="This is a list of all of the locations across the company. Here you can add, edit, or delete a location"/> },
        { name: 'Competent Trainers', component: <CompetentTrainersList scope='company' description="This is a list of all of the users across the company. Here you can add, edit, or delete a user"/> },
        { name: 'Agreement Form', component: <AgreementForm scope='company' description="Here you can edit the company's training agreement. This will also be the generic fall back agreements for all locations if it is not set in the region or a specific location."/> },
    ];

    // return veiw to be rendered
    return (
        <>
            <AdminLayout>
                <SplitCardCentered panelName='Company Settings' navigationItems={navigationItems} scope='company'/>
            </AdminLayout>
        </>
    )
}

export default CompanySettings