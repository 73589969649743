import React, { useState, useEffect, useContext } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import CardHeading from './CardHeading';
import Modal from './Modal';
import DeleteIcon from '../assets/images/icon-x-indigo.png';
import PenIcon from '../assets/images/icon-pen-indigo.png';
import MessagingContext from "../context/MessagingProvider";
import MessageInfoForm from './Messages/MessageInfoForm';
import ConfirmDeleteMessage from './Messages/ConfirmMessageDeleteModal';
import { useAlert } from "../context/AlertContext";
import { PostService, PutService, DeleteService } from '../services/GeneralServices';
import { useSelector } from 'react-redux';

function CompetentTrainersList(props){

    const [gridData, setGridData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [modalType, setModalType] = useState('edit');
	const { messages, updateMessagesState } = useContext(MessagingContext);
	const [messageToAlter, setMessageToAlter] = useState(null);
	const { addAlert } = useAlert();
	const location_id = useSelector((state) => state.location.id);

	// update gridData when messages change
	useEffect(() => {
		setGridData(messages);
	}, [messages]);


	// get message from messages by id and add it to messageToAlter
	const getMessageById = (id) => {
		const message = messages.find(m => m.id === id);
		setMessageToAlter(message);
	}

	// add button clicked
    function handleAdd() {   
        setModalType('add');
        setIsModalOpen(true);
    }

	// edit button clicked
    function handleEdit(rowData) {
        getMessageById(rowData.id);
        setModalType('edit');
        setIsModalOpen(true);
    }

	// delete button clicked
    function handleDelete(rowData) {
        getMessageById(rowData.id);
        setModalType('delete');
        setIsModalOpen(true);
    }

	// close modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

	// delete message
	async function deleteMessage(){
		const result = await DeleteService(`text-message/id/${messageToAlter.id}`);
		if (result) {
			addAlert('Message deleted successfully', 'success', true);
			updateMessagesState(messages.filter((m) => m.id !== messageToAlter.id));
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while deleting the message', 'error', true);
		}
	}

	// update message
	async function updateMessage(message){
		// get the message info to update
		let infoToUpdate = {text: message.text, title: message.title};
		const result = await PutService(`text-message/id/${messageToAlter.id}`, infoToUpdate);
		if (result) {
			addAlert('Message Updated', 'success', false);
			updateMessagesState(messages.map((m) => m.id === message.id ? message : m));
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while updating the message', 'error', true);
		}
	}

	// add message
	async function AddMessage(message){
		// add the location id to the message
		message.location_id = location_id;
		const result = await PostService(`text-messages`, message);
		if (result) {
			addAlert('Message Created', 'success', false);
			updateMessagesState([...messages, result]);
			setIsModalOpen(false);
		}else{
			addAlert('An error occurred while creating the message', 'error', true);
		}
	}



    const gridStyle = { minHeight: 300}
    const columns = [
        { name: 'id', header: 'Id', defaultVisible: false, type: 'number', defaultWidth:75 },
        { name: 'title', header: 'Title', defaultFlex: 1 },
        { name: 'text', header: 'Text', defaultFlex: 1 },		
        {
            header: 'Alter',
            render: ({ data }) => {
				return (
					<div style={{ display: 'flex', justifyContent:'center', gap: '10px' }}>
						<img src={PenIcon} className="pen-icon" alt="Edit" onClick={() => handleEdit(data)} />
						<img src={DeleteIcon} className='delete-icon' alt="Delete" onClick={() => handleDelete(data)} />
					</div>
				);
			},
            defaultFlex: 1,
        },
    ];
 
    // return veiw to be rendered
    return (
        <>
        <CardHeading 
            title='Message Library'
            description="This area is where you can create, edit, or remove messages. This is the library of 
				messages that you can choose from for sending one-time or automated text messages 
				to checked-in trainees."
        />
            <div className='card-list-wrapper'>
            <ReactDataGrid
                idProperty="id"
                style={gridStyle}
                columns={columns}
                dataSource={gridData}
            />
            </div>
            <div className='add-record-button-wrapper'>
                <button type="button" className="button-blue add-record-button" onClick={() => handleAdd()}>
                    Add New Message
                </button>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {modalType==='add' && <MessageInfoForm onClose={handleCloseModal} action='add' onSuccessfulOperation={AddMessage}/>}
                {modalType==='edit' && <MessageInfoForm onClose={handleCloseModal} action='update' message={messageToAlter} onSuccessfulOperation={updateMessage}/>}
                {modalType==='delete' && <ConfirmDeleteMessage onClose={handleCloseModal} message={messageToAlter} onSuccessfulOperation={deleteMessage}/>}
            </Modal>
        </>
    )
}

export default CompetentTrainersList