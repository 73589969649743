import { PostService, PutService } from "../../../services/GeneralServices";

const createNewTraining = async (trainee, company, region, location) => {
  try {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const unixtimestamp = Date.now();

    const file_name = `${trainee.first_name}_${trainee.last_name}_${month}_${day}_${year}`;
    const key = `${company.storage_name}/${region.storage_name}/${location.storage_name}/trainees/${trainee.first_name}_${trainee.last_name}/completed-training-pdfs/${file_name}.pdf`;
    const imageObject = {
      image: trainee.file_upload,
      type: "pdf",
      file_name: file_name,
      key: key,
    };

    await PutService("document/update-image", imageObject);

    const trainingObject = {
      pdf: `https://tervuren-company-objects.s3.us-east-2.amazonaws.com/${key}`,
      date_completed: unixtimestamp,
      manual_addition: true,
      company_id: company.id,
      region_id: region.id,
      location_id: location.id,
      trainee_id: trainee.id,
    };

    await PostService("training", trainingObject);

    await PostService(`trainee/update/${trainee.id}`, {
      ...trainee,
      last_training_video: unixtimestamp,
      last_training_agreement: unixtimestamp,
      last_training: unixtimestamp,
    });

    return true;
  } catch (error) {
    return error;
  }
};

export default createNewTraining;
