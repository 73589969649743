import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

// Styles
import "./assets/style/global-styles.scss";
import "./assets/style/components/buttons.scss";
import "./assets/style/components/forms.scss";

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AppRoutes />
      </div>
    </BrowserRouter>
  );
}
