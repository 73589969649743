import React from 'react';
import CardHeading from './CardHeading';
import { useAlert } from '../context/AlertContext';
import { DeleteTraining } from '../apiCalls/TrainingCalls';

export default function ConfirmDeleteTrainingRecord(props) {
	const trainingRecordData = props.trainingData || {};
	const record_id = trainingRecordData.id || 0;
	const { addAlert } = useAlert();

	function deleteRecord() {
		DeleteTraining(record_id).then((response) => {
			if (response.success) {
				addAlert('Training deleted successfully', 'success', true);
				props.onClose();
				if(props.onSuccessfulOperation){
					props.onSuccessfulOperation();
				}
			}else{
				addAlert('An error occurred while deleting training', 'error', true);
			}
		});
	}

  return (
    <>
		<CardHeading
			title={`Delete Record Confirmation`}
			description={`Are you sure you want to delete this Training Record? This action cannot be reversed.`}
		/>

		<div className="delete-item-text-wrapper">
			<h3><span>Trainee:</span> {trainingRecordData.trainee_first_name} {trainingRecordData.trainee_last_name}</h3>
			<h3><span>Company:</span> {trainingRecordData.trainee_company_name}</h3>
			<h3><span>Training Date:</span> {trainingRecordData.date_completed_formatted}</h3>			
		</div>

		<div className="form-bottom-button-wrapper">
			<button className="button-blue" onClick={deleteRecord}>
				Delete Record
			</button>
		</div>
    </>
  );
}
