import { get } from 'react-hook-form';
import {
  PostService,
  GetService,
  PutService,
  DeleteService,
} from '../services/GeneralServices';

/* get the information for a user */
export const GetUser = async (user_id) => {
  try {
    const response = await GetService('user/id/' + user_id);
    if (response) {
      return {
        success: true,
        data: response, // return was just response
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetAllUsers = async (type, id = null) => {
  // global is default
  let url = 'users';
  if(id !== null){
	switch(type){
	  case 'company':
		url = 'users/company/'+id;
		break;
	  case 'region':
		url = 'users/region/'+id;
		break;
	  case 'location':
		url = 'users/location/'+id;
		break;
	  default:
		url = 'users';
		break;
	}    
  }
  try {
    const response = await GetService(url);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* get the information for a user */
export const AddUser = async (userData) => {
  try {
    //const response = await PostService('auth/register', JSON.stringify(userData));
    const response = await PostService('auth/register', userData);
    if (response) {
      return {
        success: true,
        data: response, // was just retunring the response
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const UpdateUser = async (user_id, userData) => {
  try {
    const response = await PutService('user/id/' + user_id, userData);
    if (response) {
      return {
        success: true,
        data: response, // was just retunring the response
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const DeleteUser = async (user_id) => {
  try {
    const response = await DeleteService('user/id/' + user_id);
    if (response) {
      return {
        success: true,
        data: response, // was just retunring the response
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};
