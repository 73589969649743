import { GetService } from "../../services/GeneralServices";
import createNewTraining from "./functions/createNewTraining";
import createNewTrainee from "./functions/createNewTrainee";

const CreateManualTraining = async (
  addAlert,
  trainee,
  company,
  region,
  location
) => {
  console.log("company", company);
  console.log("region", region);
  console.log("location", location);
  // Step 1: Check if trainee exists
  addAlert(`Finding Trainee with phone number ${trainee.phone}...`, "loading");
  let active_trainee = await GetService(
    `trainee/phone/${trainee.phone}/${location.id}`
  );
  try {
    // Step 2: Create new trainee or Alert if trainee exists
    if (active_trainee) {
      // Trainee exists
      active_trainee.file_upload = trainee.file_upload;
      addAlert(
        `${active_trainee.display_name} was found... updating training record`,
        "loading"
      );
      console.log("active_trainee", active_trainee);
    } else {
      // Trainee does not exist
      addAlert(
        `trainee was not found... creating new trainee and training record`,
        "loading"
      );
      trainee.company_id = company.id;
      trainee.region_id = region.id;
      trainee.location_id = location.id;

      // we do this to avoid the trainee object being too large IMPORTANT DO NOT DELETE PLEASE OR REFACTOR WITH THIS IN MIND!!!!!!
      let file_upload = trainee.file_upload;
      trainee.file_upload = null;

      active_trainee = await createNewTrainee(trainee);
      if (!active_trainee) {
        addAlert("Error creating trainee", "error");
        return false;
      } else {
        // we do this to avoid the trainee object being too large IMPORTANT DO NOT DELETE PLEASE OR REFACTOR WITH THIS IN MIND!!!!!!
        active_trainee.file_upload = file_upload;
      }
    }
    // // Step 3: Create new training record
    addAlert(
      "Creating new training record and uploading it...",
      "loading",
      10000
    );

    let record = await createNewTraining(
      active_trainee,
      company,
      region,
      location
    );

    if (record) {
      addAlert("Training record created successfully", "success");
      return true;
    } else {
      addAlert("Error creating training record", "error");
      return false;
    }
  } catch (e) {
    addAlert("Error updating training record", "error");
    return false;
  }
};

export default CreateManualTraining;
