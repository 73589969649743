import "../assets/style/components/navigation.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TervurenLogo from "./TervurenLogo";
import Dropdown from "./Dropdown";
import CheckinBanner from "./CheckinBanner";
function Navigation() {
  const locationId = useSelector((state) => state.location.id);
  const active_user = useSelector((state) => state.user); // get user info
  const active_location = useSelector((state) => state.location); // get user info

  // return veiw to be rendered
  return (
    <>
      <div className="global-nav-container">
        <div className="top-nav">
          <TervurenLogo className="tervuren-logo" />

          <ul className="nav-list">
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/trainees">Trainees List</Link>
            </li>
            <li>
              <Link to="/training-records">Training Records</Link>
            </li>
            <li>
              <Link to="/checkin-records">Visitor Records</Link>
            </li>
          </ul>

          <div className="user-dropdown">
            <div className="user-info">
              <p className="welcome">{`Hello, ${active_user.first_name}`}</p>
              <p>{`${active_location.name}, ${active_location.state}`}</p>
            </div>
            <Dropdown
              name={`${active_user.first_name} ${active_user.last_name}`}
              initials={`${active_user.first_name.charAt(
                0
              )}${active_user.last_name.charAt(0)}`}
            />
          </div>
        </div>
        {/* we latch on to the websocket to allow messages to come in  */}
        {/* Since we are just making a connection we can use this same component to handle multiple other things as the backend deals with it. */}
        {/* still tryin to figure out how to make a time out parameter to pass as well to allow this to have a consistent banner and a quick real time notification one */}
        <CheckinBanner
          wsUrl={`wss://7cek3pd937.execute-api.us-east-2.amazonaws.com/production/?locationId=${locationId}`}
        ></CheckinBanner>
      </div>
    </>
  );
}

export default Navigation;
