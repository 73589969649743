/*
    the actions that will be carried out (dispatched) in this application. The actions are very self-explanatory.
*/

export const RESTART_AUTH_RESPONSE = "RESTART_AUTH_RESPONSE";
export const LOADING = "LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const CODE_ERROR = "CODE_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const RESET_FORGOT_PASSWORD_STATE = "RESET_FORGOT_PASSWORD_STATE";
export const RESET_RESET_PASSWORD_STATE = "RESET_RESET_PASSWORD_STATE";
