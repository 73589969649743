import {
  GetService,
  PutService,
  DeleteService
} from "../services/GeneralServices";

export const GetAllTrainings = async (type, id = null) => {
  // global is default
  let url = "trainings";
  if (id !== null) {
    switch (type) {
      case "company":
        url = "trainings/company/" + id;
        break;
      case "region":
        url = "trainings/region/" + id;
        break;
      case "location":
        url = "trainings/location/" + id;
        break;
      case "trainee":
        url = "trainings/trainee/" + id;
        break;
      default:
        url = "trainings";
        break;
    }
  }
  try {
    const response = await GetService(url);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetLatestTrainings = async (type, id = null) => {
  // get the right trainings
  const result = await GetAllTrainings(type, id);
  // get all trainings and then we will filter out the newest 30
  if (result.success) {
    // let trainings = result.data;
    // let newest_trainings = [];
    // filter out the newest 30

    return {
      success: true,
      data: result.data,
    };
  } else {
    return {
      success: false,
      message: result.message,
    };
  }
};

export const AddTraining = async (trainee_data) => {
  try {
    const response = await PutService("complete-training", trainee_data);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const SetBlankTrainingAgreement = async (location_info) => {
  try {
    const response = await PutService("document/blank-pdf", location_info);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetTrainingsExport = async (dataToExport) => {
  try {
    const response = await PutService("document/zip-trainings", dataToExport);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};


export const DeleteTraining = async (id) => {
	try {
	  const response = await DeleteService('training/id/' + id);
	  if(response) {
		return {
		  success: true,
		};
	  }
	} catch (error) {
	  return {
		success: false,
		message: String(error),
	  };
	}
  };