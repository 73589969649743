export default class HttpService {
  // url of the api. this needs to be changed when moving spaces!
  //url = "http://localhost:8000/api"; // development
  url = 'https://7rx419w9sf.execute-api.us-east-2.amazonaws.com'; // production

  APP_ENV =
    process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT !== 'prod' ? 'test/' : '';

  //////////////////////////////////////////////////////////////////////////
  ////////////////////////////  Request Types  ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  postData = async (item, added_url, tokenId = '') => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = this.postRequestOptions(token, item);
    return fetch(
      this.url + '/' + this.APP_ENV + added_url,
      requestOptions
    ).then((response) => response.json());
  };

  postDataForFile = async (item, added_url, tokenId = '') => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = this.postRequestOptionsForFile(token, item);
    return fetch(
      this.url + '/' + this.APP_ENV + added_url,
      requestOptions
    ).then(
      (response) => response.blob() // Handle response as a blob
    );
  };

  getData = async (added_url) => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = this.getRequestOptions(token);
    return fetch(
      this.url + '/' + this.APP_ENV + added_url,
      requestOptions
    ).then((response) => response.json());
  };

  putData = async (item, added_url) => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = this.putRequestOptions(token, item);
    return fetch(
      this.url + '/' + this.APP_ENV + added_url,
      requestOptions
    ).then((response) => response.json());
  };

  deleteData = async (added_url) => {
    const token = localStorage.getItem('accessToken');
    const requestOptions = this.deleteRequestOptions(token);
    return fetch(
      this.url + '/' + this.APP_ENV + added_url,
      requestOptions
    ).then((response) => response.json());
  };

  //////////////////////////////////////////////////////////////////////////
  //////////////////////////// Request Options ////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  getRequestOptions = (token) => {
    let requestOptions = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
    };
    return requestOptions;
  };

  postRequestOptions = (token, item) => {
    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  putRequestOptions = (token, item) => {
    let requestOptions = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  deleteRequestOptions = (token) => {
    let requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-type': 'application/json',
      },
      // body is typically not needed for DELETE requests
    };
    return requestOptions;
  };

  // Request options for file download (similar to postRequestOptions, but can be adjusted for file download if needed)
  postRequestOptionsForFile = (token, item) => {
    let requestOptions = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };
} //[end] HttpService class
