import React from "react";
import AdminLayout from "../displays/AdminLayout";
import SplitCardCentered from "../../components/SplitCardCentered";
import MessageSender from "../../components/MessageSender/MessageSender";
import MessageLibrary from "../../components/MessageLibrary";
import AutomaticMessagesList from "../../components/AutomaticMessages/AutomaticMessagesList";

const Messaging = () => {
  const navigationItems = [

    {
		name: "Message Library",
		component: <MessageLibrary/>,
	},
    {
      name: "One Time Message",
      component: <MessageSender />,
    },
    {
      name: "Automated Messages",
      component: <AutomaticMessagesList />,
    },
  ];

  return (
    <AdminLayout>
      <SplitCardCentered
        panelName="Messages"
        navigationItems={navigationItems}
        scope="location"
      />
    </AdminLayout>
  );
};

export default Messaging;
