/* 
    this is used for multiple instances, so there is a parameter named 'scope' passed in that 
    allows us to choose how to handle the data. This component is used for:
        - global settings
        - company settings
        - region settings
        - location settings

    passed in via scope: global | company | region | location
*/

import React, { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CardHeading from "../CardHeading";
import { useAlert } from "../../context/AlertContext";
import { useSelector } from "react-redux";
import { GetLocation, UpdateLocation } from "../../apiCalls/LocationCalls";

export default function AgreementForm(props) {
  const { addAlert } = useAlert();
  const location = useSelector((state) => state.location);
  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {
      items: [{ type: "text", title: "", body: "" }],
      isForm: "false",
    },
  });

  // get the agreement data from the location and set it to the form
  useEffect(() => {
    async function loadFormData() {
      try {
        const result = await GetLocation(location.id); // TODO: make this dynamic
        if (result.success) {
          var agreementForm = result.data.agreement;
          //console.log(agreementForm);
          //setSavedAgreementForm(agreementForm);
          // Reset the form with the new data
          reset(agreementForm);
        } else {
          // Handle the case when success is false
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error
        addAlert("Error fetching data:" + error, "error", true);
      }
    }
    loadFormData();
  }, [location, reset, addAlert]);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "items",
  });

  // handle the form submission
  async function onSubmit(data) {
    // set data to send
    const to_send = { agreement: data };

    // update location
    UpdateLocation(location.id, to_send)
      .then((response) => {
        if (response.success) {
          addAlert("Agreement Form Updated", "success", false);
        }
      })
      .catch((error) => {
        console.error(error);
        addAlert("An error occurred retrieving the data", "error", true);
      });
  }

  function onDragEnd(result) {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  }

  const submitNomenclature = () => (data) => {
    //map over the data if the item has a key of items or isForm put it into an agreement object
    const agreement = {};
    Object.keys(data).map((key) => {
      if (key === "items" || key === "isForm") {
        agreement[key] = data[key];
        // remove it from the data object
        delete data[key];
      }
    });
    // set data to send
    const to_send = { ...data, agreement: agreement };
    console.log(to_send);
    console.log("submitting nomenclature");

    UpdateLocation(location.id, to_send)
      .then((response) => {
        if (response.success) {
          addAlert("Agreement Nomenclature Updated", "success", false);
        }
      })
      .catch((error) => {
        console.error(error);
        addAlert("An error occurred retrieving the data", "error", true);
      });
  };

  return (
    <>
      <CardHeading
        title="Training Agreements"
        description={props.description || ""}
      />
      <form onSubmit={handleSubmit(submitNomenclature())}>
        <div className="radio-buttons-section">
          <div className="top-fields row">
            <div class="half-width">
              <label htmlFor="date_of_training_placeholder">
                Date of Training Placeholder
              </label>
              <input
                id="date_of_training_placeholder"
                type="text"
                placeholder="loading"
                {...register("date_of_training_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="trainee_name_placeholder">
                Trainee Name Placeholder
              </label>
              <input
                id="trainee_name_placeholder"
                type="text"
                placeholder="loading"
                {...register("trainee_name_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="company_placeholder">
                Company Name Placeholder
              </label>
              <input
                id="company_placeholder"
                type="text"
                placeholder="loading"
                {...register("company_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="training_location_placeholder">
                Location Placeholder
              </label>
              <input
                id="training_location_placeholder"
                type="text"
                placeholder="loading"
                {...register("training_location_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="scalehouse_attendant_placeholder">
                Trainer Name Placeholder
              </label>
              <input
                id="scalehouse_attendant_placeholder"
                type="text"
                placeholder="Loading"
                {...register("scalehouse_attendant_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="email_placeholder">Email Placeholder</label>
              <input
                id="email_placeholder"
                type="text"
                placeholder="loading"
                {...register("email_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="phone_placeholder">Phone Placeholder</label>
              <input
                id="phone_placeholder"
                type="text"
                placeholder="loading"
                {...register("phone_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="truck_number_placeholder">
                Truck Number Placeholder
              </label>
              <input
                id="truck_number_placeholder"
                type="text"
                placeholder="loading"
                {...register("truck_number_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="msha_number_placeholder">
                MSHA Number Placeholder
              </label>
              <input
                id="msha_number_placeholder"
                type="text"
                placeholder="loading"
                {...register("msha_number_placeholder")}
              />
            </div>
            <div class="half-width">
              <label htmlFor="training_performed_placeholder">
                Training Performed Placeholder
              </label>
              <input
                id="training_performed_placeholder"
                type="text"
                placeholder="loading"
                {...register("training_performed_placeholder")}
              />
            </div>
          </div>
        </div>
      </form>

      <div className="radio-buttons-section">
        <label>
          <input type="radio" value="false" {...register("isForm")} />
          User May Consent To All Agreements At Once
        </label>
        <label>
          <input type="radio" value="true" {...register("isForm")} />
          User Must Consent To Each Agreement
        </label>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <form onSubmit={handleSubmit(submitNomenclature())}>
          <Droppable droppableId="droppable-fields">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="agreement-box"
                      >
                        <select
                          {...register(`items.${index}.type`)}
                          defaultValue="text"
                        >
                          <option value="text">Text</option>
                          <option value="checkbox">Checkbox</option>
                          <option value="bold">Bold</option>
                          <option value="list">List</option>
                        </select>
                        <p>Title</p>
                        <input
                          type="text"
                          placeholder="Section Title"
                          {...register(`items.${index}.title`)}
                        />
                        <p>Content</p>
                        {item.type === "list" ? (
                          <ul>
                            {item.items.map((listItem, listIndex) => (
                              <li key={listIndex}>
                                <input
                                  type="text"
                                  placeholder="List Item"
                                  {...register(
                                    `items.${index}.items.${listIndex}`
                                  )}
                                />
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <textarea
                            placeholder="Section Content"
                            {...register(`items.${index}.body`)}
                          />
                        )}
                        <div
                          className="remove-icon"
                          onClick={() => remove(index)}
                        >
                          <span></span>
                          <span></span>
                        </div>
                        <div className="move-icon">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div
            className="add-new-agreement-button"
            onClick={() => append({ title: "", body: "" })}
          >
            <p>Add New Agreement</p>
            <div className="add-icon">
              <span></span>
              <span></span>
            </div>
          </div>

          <div className="form-bottom-button-wrapper">
            <button
              type="submit"
              className="button-blue"
              onClick={handleSubmit(submitNomenclature())}
            >
              Save Changes
            </button>
          </div>
        </form>
      </DragDropContext>
    </>
  );
}
