import React from "react";

import AlertContextProvider from "../AlertContext";
import { TraineeProvider } from "../TraineeProvider";

export function TervurenProvider({ children }) {
  // we can now inject other context into the provider component while making it semantic
  return (
    <TraineeProvider>
      <AlertContextProvider>{children}</AlertContextProvider>
    </TraineeProvider>
  );
}
