import AdminLayout from '../displays/AdminLayout';
import SplitCardCentered from '../../components/SplitCardCentered';
import CompetentTrainerInfoForm from '../../components/settings/CompetentTrainerInfoForm';
import { useSelector } from "react-redux";


function MyAccount(){
    const current_user = useSelector((state) => state.user);

    const navigationItems = [
        { name: 'Account Settings', component: <CompetentTrainerInfoForm  action='update' id={current_user.id} type='myaccount'/> },
    ];

    return (
        <>
            <AdminLayout>
                <SplitCardCentered panelName='My Account' navigationItems={navigationItems} scope='location'/>
            </AdminLayout>
        </>
    )
}

export default MyAccount