import { useEffect } from "react";
import CardHeading from "../CardHeading";
import { useState } from "react";

const AutomaticMessageConfirmDelete = (props) => {
	const [message, setMessage] = useState({title: "", message: ""});
	const [trainees, setTrainees] = useState([]);
	useEffect(() => {
		setMessage(props.message);
		console.log("message", message);
	}
	, [props.message, message]);

	useEffect(() => {
		setTrainees(props.trainees);
		console.log("trainees", trainees);
	}	
	, [props.trainees, trainees]);

	// const getTraineeNameByPhone = (phone) => {
    //     const trainee = trainees.find(t => t.phone === phone);
    //     return trainee ? `${trainee.first_name} ${trainee.last_name}` : phone;
    // };

  return (
	<>
		<CardHeading
			title={`Delete Message Confirmation`}
			description={`Are you sure you want to delete this Message? This action cannot be reversed.`}
		/>

		<div className="delete-item-text-wrapper">
			<h3><span>Title:</span> {message.title}</h3>
			<h3><span>Message:</span> {message.message}</h3>
			{/* <h3><span>Message Recipients:</span> {message.recipients[0] === "checked_in" ? "All Checked In Trainees" : "Specific Trainees"}</h3>
			
			{message.recipients[0] !== "checked_in" && message.recipients.length > 1 && (
                    <ul>
                        {message.recipients.map((recipient, index) => (
                            <li key={index}>{recipient}</li>
                        ))}
                    </ul>
                )} */}
		</div>

		<div className="form-bottom-button-wrapper">
			<button className="button-blue" onClick={props.onSuccessfulOperation}>
				Delete Message
			</button>
		</div>
    </>
  );
};

export default AutomaticMessageConfirmDelete;
