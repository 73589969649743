import Navigation from "../../components/Navigation";
import CompanyLogo from "../../components/CompanyLogo";
import "../../assets/style/containers/records-layout.scss";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import Modal from "../../components/Modal";
import AddTrainingForm from "../Modals/AddTrainingForm";
import AddCheckInForm from "../Modals/AddCheckInForm";
import { set, useForm } from "react-hook-form";
import Loader from "../../components/Loader";
import "../../assets/scripts/recordsSearchBar";
import { GetTrainingsExport } from "../../apiCalls/TrainingCalls";
import CreateManualTraining from "../../helpers/CreateManualTraining/CreateManualTraining";
import { useAlert } from "../../context/AlertContext";
/* if we want to add text highlighting, we will have to used some modified version of the funtion render on this page ->  https://reactdatagrid.io/docs/miscellaneous#csv-export-+-custom-search-box */

export default function RecordsLayout(props) {
  const active_location = useSelector((state) => state.location); // get user info
  const active_company = useSelector((state) => state.company); // get user info
  const active_region = useSelector((state) => state.region); // get user info

	const [columns, setColumns] = useState(props.columns || []);
	const originalColumnsRef = useRef(props.columns || []);
	const [columnsSaved, setColumnsSaved] = useState(props.columnsSaved || false);

	const [groups] = useState(props.groups); // get columns
	const [gridRef, setGridRef] = useState(null); // refference to the grid
	const [isModalOpen, setIsModalOpen] = useState(false); // for add training popup
	const [isLoading, setIsLoading] = useState(false); // for showing Loader
	const exportFileName = props.exportFileName || "grid-data.csv"; // for download name of file including extension
	const recordType = props.recordType || "Training";
	const { addAlert } = useAlert();

  

  const handleCloseModal = () => {
    setIsModalOpen(false);
    // refresh data
  };

  /* stuff for the drawer */
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  /* * [start] handle data changes * */
  const [dataSource, setDataSource] = useState(props.dataSource);
  const originalDataRef = useRef(props.dataSource);

  // watch for changes in the data that comes in
  useEffect(() => {
    setDataSource(props.dataSource);
    originalDataRef.current = props.dataSource;
  }, [props.dataSource]);
  /* * [end] handle data changes * */

  const handleAddTrainingRecord = async (event) => {
    let active_trainee = event;
    let item = await CreateManualTraining(
      addAlert,
      active_trainee,
      active_company,
      active_region,
      active_location
    );
    if (item === true) {
      setIsModalOpen(false);
	  if (props.onSuccessfulOperation) {
		props.onSuccessfulOperation();
	  }
    }
  };

  /* * [start] handle the records export * */
  // Determine which export function to use based on a prop
  const handleExport = () => {
    setIsLoading(true); // start loader
    if (props.exportType === "csv") {
      exportCSV();
    } else if (props.exportType === "package") {
      exportPackage();
    } else {
      console.error("Invalid export type");
      setIsLoading(false); // stop loader
    }
  };

  // function to download the file once it is made
  const downloadBlob = (blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = exportFileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setIsLoading(false); // stop loader
  };

  // create the csv from the visible records
  const SEPARATOR = ",";
  const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;
    const header = columns.map((c) => c.header).join(SEPARATOR);
    const rows = gridRef.current.data.map((data) =>
      columns
        .map((c) => {
          // Check if a custom render function exists, otherwise use the default value
          const cellData = c.render
            ? c.render({ data })
            : data[c.name] || "N/A";

          // Convert to string and handle potential undefined or null values
          const cellDataString =
            typeof cellData !== "undefined" && cellData !== null
              ? cellData.toString()
              : "N/A";

          // Escape double quotes for CSV
          return '"' + cellDataString.replace(/"/g, '""') + '"';
        })
        .join(SEPARATOR)
    );

    // create download object
    const contents = [header].concat(rows).join("\n");
    const blob = new Blob([contents], { type: "text/csv;charset=utf-8;" });
    downloadBlob(blob);
  };

  // function to download the exported files once it is made
  const exportPackage = async () => {
    const ids = dataSource.map((item) => item.id);
    let exportData = {
      keys: ids, // ids of the records to export
      relation: "location", // company, region, or location
      relation_id: active_location.id, // the id of the company, region, or location
      time: "90", // duration back in days to search
    };
  
    const packageExport = await GetTrainingsExport(exportData);
    if (packageExport.success) {
      const downloadUrl = packageExport.data; // The presigned URL you get from the API

      // Ensure the URL is HTTPS to enhance security during transmission
      if (downloadUrl.startsWith("https://")) {
        // Create a temporary anchor element and trigger a download
        const downloadLink = document.createElement("a");
        document.body.appendChild(downloadLink);
        downloadLink.style.display = "none";
        downloadLink.href = downloadUrl;
        downloadLink.download = "completed-training.zip"; // Suggest a filename for the download

        downloadLink.click();
        document.body.removeChild(downloadLink);
        setIsLoading(false); // stop loader
      } else {
        console.error(
          "Download URL is not secure (HTTPS required):",
          downloadUrl
        );
        // Handle the error (show a message to the user, log it, etc.)
      }
    } else {
      console.log("Error exporting package:", packageExport.message);
    }

  };
  /* * [end] handle the records export * */

  /* * [start] handle the search * */
  const [searchText, setSearchText] = useState("");
  const searchTextRef = useRef(searchText);
  searchTextRef.current = searchText;

  // helper function in case the value is inside an array
  function getValueByPath(obj, path) {
    return path.split(".").reduce((accum, part) => accum && accum[part], obj);
  }

  const onSearchChange = ({ target: { value } }) => {
    const visibleColumns = gridRef.current.visibleColumns;
    const lowerSearchText = value && value.toLowerCase();
    //reload what is showing
    const newData = originalDataRef.current.filter((p) => {
      return visibleColumns.reduce((acc, col) => {
        const v = col.path ? getValueByPath(p, col.path) : p[col.id]; // for if value is in sub object
        const lowerValue = (v + "").toLowerCase(); // get string value
        return acc || lowerValue.indexOf(lowerSearchText) !== -1; // make the search case insensitive
      }, false);
    });

    setSearchText(value);
    setDataSource(newData);
  };
  /* * [end] handle the search * */

  // drawer filter stuff

  const {
    register,
    handleSubmit,
    // watch,
    setValue,
    reset,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      recordStatus: "all",
    },
  });
  const [showAllCompanies, setShowAllCompanies] = useState(false);

  // Create a map of unique companies
  function normalizeCompanyName(name) {
    return name.toLowerCase().replace(/[^a-z0-9]/g, "");
  }

  function simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash &= hash; // Convert to 32bit integer
    }
    return hash;
  }

  const companiesMap = new Map();

  dataSource.forEach((data) => {
    //const company = data.trainee_company;
    const company_name = data.trainee_company_name;
    // Skip if company_name is undefined, null, or an empty string
    if (!company_name) {
      return; // Continue to the next iteration
    }
    let company_id = simpleHash(normalizeCompanyName(company_name));

    if (company_name && company_id && !companiesMap.has(company_id)) {
      companiesMap.set(company_id, {
        id: company_id, // Use the id from trainee_company
        name: company_name, // Use the name from trainee_company
      });
    }
  });

  // Step 2: Convert the map to an array
  const companies = Array.from(companiesMap.values());

  // Slice the array to show either first 8 or all companies based on state
  const displayedCompanies = showAllCompanies
    ? companies
    : companies.slice(0, 8);

  /* Clear the form */
  const clearForm = () => {
    setDataSource(originalDataRef.current); // Reset data source to original data

    // Reset the form values
    const resetValues = {
      recordStatus: "all",
      recordStartDate: "",
      recordEndDate: "",
      selectedCompanies: {},
    };

    companies.forEach((company) => {
      // Manually reset each checkbox's state
      const identifier = companyIdentifiers[company];
      resetValues.selectedCompanies[identifier] = false;
      setValue(`selectedCompanies.${identifier}`, false);
    });

    reset(resetValues); // Reset the form with new values
    setSelectedStatus("all"); // Reset selected status
  };

  // map companies to an object with the company name as the key and a arbitrary company ID as the value. !this id does not relate to the company's id in the database!
  const companyIdentifiers = companies.reduce((acc, company, index) => {
    if (typeof company.name !== "string") {
      console.error("Unexpected company data:", company);
    }
    const identifier = simpleHash(normalizeCompanyName(company.name));
    acc[company.name] = identifier;
    return acc;
  }, {});

  /* Submit the form */
  const handleFilterSubmit = (formData) => {
    filterGridData(formData);
  };

  const [selectedStatus, setSelectedStatus] = useState("all");

  /* Handle the training status changes */
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setValue("recordStatus", status);
  };

  // Function to filter data based on form submission

  const filterGridData = (filters) => {
    const { selectedCompanies, recordStartDate, recordEndDate, recordStatus } =
      filters;

    const filteredData = originalDataRef.current.filter((data) => {
      // Check if any company is selected
      const isAnyCompanySelected = Object.values(selectedCompanies).some(
        (value) => value
      );

      let matchesCompany = true;
      if (isAnyCompanySelected) {
        // Use the identifier to check if the company is selected
        const companyIdentifier = companyIdentifiers[data.trainee_company_name];
        matchesCompany = selectedCompanies.hasOwnProperty(companyIdentifier)
          ? selectedCompanies[companyIdentifier]
          : false;
      }

      // Check if date range is specified
      let matchesDateRange = true;

      // Only perform date checks if either start date or end date is provided
      if (recordStartDate || recordEndDate) {
        // Convert string dates to Date objects
        const startDate = recordStartDate
          ? new Date(recordStartDate)
          : new Date(-8640000000000000); // Use a very old date if start date is not provided
        const endDate = recordEndDate
          ? new Date(recordEndDate)
          : new Date(8640000000000000); // Use a future date if end date is not provided

        if (data.date_completed_formatted) {
          const recordDateParts = data.date_completed_formatted.split("/");
          const recordYear = parseInt(recordDateParts[2], 10);
          const recordMonth = parseInt(recordDateParts[0], 10) - 1; // Month is 0-indexed
          const recordDay = parseInt(recordDateParts[1], 10);
          const recordDate = new Date(recordYear, recordMonth, recordDay);

          matchesDateRange = recordDate >= startDate && recordDate <= endDate;
        } else if (data.checkin_date) {
          const recordDateParts = data.checkin_date.split("/");
          const recordYear = parseInt(recordDateParts[2], 10);
          const recordMonth = parseInt(recordDateParts[0], 10) - 1; // Month is 0-indexed
          const recordDay = parseInt(recordDateParts[1], 10);
          const recordDate = new Date(recordYear, recordMonth, recordDay);

          matchesDateRange = recordDate >= startDate && recordDate <= endDate;
        } else {
          matchesDateRange = false; // If there's no date_completed_formatted, don't include this record
        }
      }

      // Check if status is specified
      let matchesStatus =
        recordStatus === "all" || data.status === recordStatus;

      return matchesCompany && matchesDateRange && matchesStatus;
    });

    setDataSource(filteredData);
  };



	/* -----------------------------------------------------
	* [start] handle column order change
	*/

	
	 

	const handleColumnOrderChange = (columnOrder) => {
		if (!columnOrder || !columns) return;
	
		const newColumns = columnOrder.map((columnId) =>
			columns.find((col) => col.name === columnId)
		).filter(col => col !== undefined); // filter out undefined columns
	
		setColumns(newColumns);
		if (props.onColumnChange) {
			props.onColumnChange(newColumns);
		}
	};
	
	const resetColumns = () => {
		if (props.onResetColumns) {
			props.onResetColumns();
		}
	};

	useEffect(() => {
		setColumns(props.columns || []);
	}, [props.columns]);
	
	useEffect(() => {
		setColumnsSaved(props.columnsSaved);
	}, [props.columnsSaved]);

	/*
	* [end] handle column order change
	* -----------------------------------------------------
	*/

	


  // return veiw to be rendered
  return (
    <>
      <Navigation></Navigation>
      {/* show loader if needed */}
      {isLoading && <Loader />}
      <div className="main-container">
        <div className={`drawer ${drawerOpen ? "open" : ""}`}>
          <div className="drawer-tab" onClick={toggleDrawer}></div>
          <div className="drawer-content">
            <form>
              <div className="drawer-section">
                <p className="drawer-title">Filter Results</p>
                {/* <p className="clear-filters" onClick={clearForm}>
                  Clear All
                </p> */}
              </div>

              <div className="drawer-section">
                <p className="drawer-title">Company</p>
                {displayedCompanies.map((company) => (
                  <label key={company.id}>
                    <input
                      type="checkbox"
                      {...register(
                        `selectedCompanies.${companyIdentifiers[company.name]}`
                      )}
                    />
                    {company.name}
                  </label>
                ))}
              </div>

              <button
                className="show-more-btn"
                type="button"
                onClick={() => setShowAllCompanies(!showAllCompanies)}
              >
                {showAllCompanies ? "Show Less" : "Show More"}
              </button>

			  {recordType !== 'Trainee' && (
				<div className="drawer-section">
					<p className="drawer-title">
					{recordType === "Visitor" ? "Visit Date" : "Training Date"}
					</p>
					<div className="training-date-wrapper">
					<label className="training-date">
						{recordType === "Visitor"
						? "Visit Start Date:"
						: "Start Date"}
						<input type="date" {...register("recordStartDate")} />
					</label>
					<label className="training-date">
						{recordType === "Visitor" ? "Visit End Date:" : "End Date"}
						<input type="date" {...register("recordEndDate")} />
					</label>
					</div>
				</div>
			  )}
              {recordType === "Visitor" ? (
                <div className="drawer-section">
                  <p className="drawer-title">Visitor Status</p>
                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "all"}
                      onChange={() => handleStatusChange("all")}
                    />
                    All
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "On Site"}
                      onChange={() => handleStatusChange("On Site")}
                    />
                    Onsite
                  </label>
                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "offsite"}
                      onChange={() => handleStatusChange("offsite")}
                    />
                    Offsite
                  </label>
                </div>
              ) : (
                <div className="drawer-section">
                  <p className="drawer-title">Training Status</p>
                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "all"}
                      onChange={() => handleStatusChange("all")}
                    />
                    All
                  </label>

                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "active"}
                      onChange={() => handleStatusChange("active")}
                    />
                    Active
                  </label>

                  <label>
                    <input
                      type="radio"
                      checked={selectedStatus === "expired"}
                      onChange={() => handleStatusChange("expired")}
                    />
                    Expired
                  </label>
				  {recordType === 'Trainee' && (
					<label>
						<input
						type="radio"
						checked={selectedStatus === "no training"}
						onChange={() => handleStatusChange("no training")}
						/>
						No Training
                  </label>
				  )}

                </div>
              )}

              <button
                type="submit"
                className="button button-light"
                onClick={handleSubmit(handleFilterSubmit)}
              >
                Run Report
              </button>
            </form>
          </div>
        </div>

        <div className={`records-container ${drawerOpen ? "shifted" : ""}`}>
          <div className="header">
            <div className="logo-and-title">
              <div className="logo-wrapper">
                <CompanyLogo />
              </div>
              <h1>{props.title}</h1>
            </div>
            <div className="buttons-wrapper">
              {recordType === "Training" && (
                <>
                  <button
                    type="button"
                    className="button-light"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Add Record
                  </button>
                  <button
                    type="button"
                    className="button-blue"
                    onClick={handleExport}
                  >
                    Export Record{dataSource.length !== 1 ? "s" : ""}
                  </button>
                </>
              )}

              {recordType === "Visitor" && (
                <>
                  <button
                    type="button"
                    className="button-light"
                    onClick={() => setIsModalOpen(true)}
                  >
                    Add Record
                  </button>
                  <button
                    type="button"
                    className="button-blue"
                    onClick={handleExport}
                  >
                    Export Record{dataSource.length !== 1 ? "s" : ""}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="info-bar">
            <div className="count-and-filters">
              <div className="record-count">
                <p>
                  Found {dataSource.length} Record
                  {dataSource.length !== 1 ? "s" : ""}
                </p>
              </div>
			  <div className="reset-columns">
				{columnsSaved && (
					<button type="button" onClick={resetColumns} className="button-light">
						Reset Columns
					</button>
				)}
			</div>
            </div>
            <div className="location-and-sort">
              <p>{`${active_location.name}, ${active_location.state}`}</p>
            </div>
          </div>
          <div className="searchbar">
            <label>
              <input
                type="text"
                value={searchText}
                onChange={onSearchChange}
                placeholder="Search"
                id="searchbar"
              />{" "}
            </label>
          </div>
          <div className="grid-container">
            <ReactDataGrid
			  key={props.key}
              idProperty="id"
              handle={setGridRef}
              style={props.style}
              columns={columns}
              groups={groups}
              dataSource={dataSource}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        
        {props.addRecordType === "Training" && (
          <AddTrainingForm
            onSubmit={handleAddTrainingRecord}
            onClose={handleCloseModal}
            onSuccessfulOperation={props.onSuccessfulOperation}
          />
        )}
        {props.addRecordType === "CheckIn" && (
          <AddCheckInForm
            onClose={handleCloseModal}
            onSuccessfulOperation={props.onSuccessfulOperation}
          />
        )}
      </Modal>
    </>
  );
}
