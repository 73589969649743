import { useState } from "react";

const MultiplePageUpload = ({ onFilesChange }) => {
  const [pages, setPages] = useState([{ image: null }]);

  const handleImageChange = (event, index) => {
    const { files } = event.target;
    const newPages = [...pages];
    newPages[index] = { image: files[0] };

    // Add a new page if there are no empty pages left
    if (!newPages.some((page) => page.image === null)) {
      newPages.push({ image: null });
    }
    setPages(newPages);

    // Pass only non-null images to the parent component
    onFilesChange(
      newPages.filter((page) => page.image !== null).map((page) => page.image)
    );
  };

  const handleDelete = () => {
    setPages([{ image: null }]);
    onFilesChange([]);
  };

  return (
    // <div className="flex gap-1 column flex-start">
    <div className="manual-training-file-upload-wrapper">
		<div className="manual-training-file-upload">
			{pages.map((page, index) => (
				<div key={index}>
				<label>Page {index + 1}</label>
				<input
					type="file"
					onChange={(e) => handleImageChange(e, index)}
					defaultValue={page.image}
				/>
				</div>
			))}
	  	</div>
		<div className="manual-training-file-upload-button-wrapper">
			<button
				className="button button-gray"
				onClick={(e) => {
				e.preventDefault();
				handleDelete();
				}}
			>
				Reset Page Uploads
			</button>
	  	</div>
    </div>
  );
};

export default MultiplePageUpload;
