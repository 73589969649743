import {
  PostService,
  GetService,
  PutService,
  DeleteService,
} from '../services/GeneralServices';

/* get the information for a company */
export const GetCheckIn = async (checkin_id) => {
  try {
    const response = await GetService('/checkin/id/' + checkin_id);
    if (response.hasOwnProperty('success') && response.success) {
      return {
        success: true,
        info: response.data.location_info,
        message: response,
      };
    } else {
      return {
        success: false,
        message: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* get the information for a company */
export const GetAllCheckIns = async (type, id = null) => {
  // global is default
  let url = 'checkins';
  if (id !== null) {
	switch (type) {
		case 'company':
		url = 'checkins/company/' + id;
		break;
		case 'region':
		url = 'checkins/region/' + id;
		break;
		case 'location':
		url = 'checkins/location/' + id;
		break;
		default:
		url = 'checkins';
		break;
	}
  }
  try {
    const response = await GetService(url);
    if (response) {
      return {
        success: true,
        data: response, // was info
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* add single location */
export const AddCheckIn = async (formData) => {
  try {
    const response = await PostService('checkin', formData);

    if (response.hasOwnProperty('success') && response.success) {
      return {
        success: true,
        message: response,
      };
    } else {
      return {
        success: false,
        message: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* get all info for the location */
export const UpdateCheckIn = async (formData) => {
  //let url = "update-info";
  let checkin_id = formData.get('checkin_id');
  try {
    const response = await PutService('checkin/id/' + checkin_id, formData);

    if (response.hasOwnProperty('success') && response.success) {
      return {
        success: true,
        message: response,
      };
    } else {
      return {
        success: false,
        message: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

// expect checkin_ids to be an array of checkin IDs
export const DeleteCheckIn = async (checkin_ids) => {
    try {
        // Filter out null or undefined IDs
        const validCheckinIds = checkin_ids.filter(id => id !== null && id !== undefined);

        // Map valid IDs to delete promises
        const deletePromises = validCheckinIds.map(checkin_id => DeleteService('checkin/id/' + checkin_id));

        // Wait for all delete operations to complete
        const responses = await Promise.all(deletePromises);

        // Filter out failed responses (where response is false)
        const failedResponses = responses.filter(response => !response);

        if (failedResponses.length === 0) {
            return {
                success: true,
                message: 'All check-ins deleted successfully',
            };
        } else {
            return {
                success: false,
                message: 'Some check-ins could not be deleted',
                details: failedResponses,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error),
        };
    }
};


export const GetLatestCheckIns = async (type, id = null) => {
  // get the right check ins
  const result = await GetAllCheckIns(type, id);
  // get all trainings and then we will filter out the newest 30
  if (result.success) {
    let checkins = result.data;
    // let newest_checkins = [];
    // filter out the newest 30

    return {
      success: true,
      data: checkins, // was checkins
    };
  } else {
    return {
      success: false,
      message: result.message,
    };
  }
};
