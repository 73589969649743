import jsPDF from "jspdf";

const ConvertImagesToPdf = async (files, type, outputFilename) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  for (const [index, file] of files.entries()) {
    if (index > 0) {
      doc.addPage();
    }

    const imgData = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

    const image = new Image();
    image.src = imgData;

    await new Promise((resolve) => {
      image.onload = () => {
        const imgWidth = image.width;
        const imgHeight = image.height;

        const aspectRatio = imgWidth / imgHeight;
        let scaledWidth = pageWidth - 20; // Allow for margins
        let scaledHeight = scaledWidth / aspectRatio;

        if (scaledHeight > pageHeight - 20) {
          // If it exceeds page height, resize
          scaledHeight = pageHeight - 20;
          scaledWidth = scaledHeight * aspectRatio;
        }

        doc.addImage(imgData, "JPEG", 10, 10, scaledWidth, scaledHeight);
        resolve();
      };
    });
  }

  const pdfBlob = doc.output("blob");
  return pdfBlob;
};

export default ConvertImagesToPdf;
