import React from 'react';
import CardHeading from './CardHeading';
import { useAlert } from '../context/AlertContext';
import { DeleteCheckIn } from '../apiCalls/CheckInCalls';

export default function ConfirmDeleteVisitorRecord(props) {
	const visitorRecordData = props.trainingData || {};
	console.log('visitorRecordData', visitorRecordData);
	const { addAlert } = useAlert();

	function deleteRecord() {
		DeleteCheckIn([visitorRecordData.checkin_id, visitorRecordData.checkout_id]).then((response) => {
			if (response.success) {
				addAlert('Visitor Record deleted successfully', 'success', true);
				
				if(props.onSuccessfulOperation){
					props.onSuccessfulOperation();
				}
				props.onClose();
			}else{
				addAlert('An error occurred while deleting training', 'error', true);
			}
		});
	}

  return (
    <>
		<CardHeading
			title={`Delete Record Confirmation`}
			description={`Are you sure you want to delete this Check-In/Check-Out Record? This action cannot be reversed.`}
		/>

		<div className="delete-item-text-wrapper">
			<h3><span>Trainee:</span> {visitorRecordData.trainee_first_name} {visitorRecordData.trainee_last_name}</h3>
			<h3><span>Company:</span> {visitorRecordData.trainee_company_name}</h3>
			<h3><span>Date:</span> {visitorRecordData.checkin_date}</h3>	
			<h3><span>Check-in:</span> {visitorRecordData.checkin_time}</h3>	
			<h3><span>Check-Out:</span> {visitorRecordData.checkout_time}</h3>			
		</div>

		<div className="form-bottom-button-wrapper">
			<button className="button-blue" onClick={deleteRecord}>
				Delete Record
			</button>
		</div>
    </>
  );
}
