import {PostService, GetService, PutService, DeleteService} from '../services/GeneralServices';


/* get the information for a company */
export const GetCompany = async (company_id) => {
    try {
        const response = await GetService('company/id/'+company_id);
        if(response){
            return {
                success: true,
                data: response, // * was info
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
};


/* get all of the companies in the system*/
export const GetAllCompanies = async () => {
    try {
        const response = await GetService('companies');
        if(response){
            return {
                success: true,
                data: response,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
};

/* add single company */
export const AddCompany = async (formData) => {
    // will probably have to change how this form data is being sent to be a json file. this was because of trying to send an image
    try {
        const response = await PostService('company', formData);
        if(response){
            return {
                success: true,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
};


/* get all info for the company */
export const UpdateCompany = async (company_id, formData) => {
    // will probably have to change how this form data is being sent to be a json file. this was because of trying to send an image
    //let company_id = formData.get('company_id');
    try {
        const response = await PutService('company/id/' + company_id, formData); 
        if(response){
            return {
                success: true,
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
};


/* delete single company location */
export const DeleteCompany = async (company_id) => {
    try { 
        const response = await DeleteService('company/id/' + company_id);

        if (response.hasOwnProperty('success') && response.success) {
            return {
                success: true,
                message: response
            };
        } else {
            return {
                success: false,
                message: response
            };
        }
    } catch (error) {
        return {
            success: false,
            message: String(error)
        };
    }
};







