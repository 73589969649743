//import React from "react";
function RenderNavItem({ item, setActiveComponent, activeComponent, level }) {
    const indent = 10; // You can adjust the indentation value as needed
    return (
      <div style={{ marginLeft: level * indent + 'px' }}>
        <div 
          onClick={item.component ? () => setActiveComponent(item.component) : undefined} 
          style={{
            marginBottom: '10px', 
            position: 'relative', 
            fontWeight: item.isTitle ? 'bold' : 'normal',
            cursor: item.component ? 'pointer' : 'default',
          }}
        >
          {item.name}
          {activeComponent === item.component && <span style={{ position: 'absolute', left: -(indent) + 'px', color: 'blue' }}>•</span>}
        </div>
        {item.subItems && item.subItems.map(subItem => (
          <RenderNavItem 
            key={subItem.name} 
            item={subItem} 
            setActiveComponent={setActiveComponent} 
            activeComponent={activeComponent}
            level={level + 1} // Increase level for sub-items
          />
        ))}
      </div>
    );
  }
  


function CardNavigation({ navigationItems, setActiveComponent, activeComponent }) {

    return (
        <div>
            {navigationItems.map(item => (
                <RenderNavItem 
                    key={item.name} 
                    item={item} 
                    setActiveComponent={setActiveComponent} 
                    activeComponent={activeComponent} 
                    level={0} // Initial level
                />
            ))}
        </div>
      );
}

export default CardNavigation;
