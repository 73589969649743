import { createSlice } from "@reduxjs/toolkit";

/**
 * Default state object with initial values.
 */
const initialState = {
  // for when not testing, below is the standard initial state
  display: "training",
  screen: "training-lobby",
};

/**
 * Create a slice as a reducer containing actions.
 */
export const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setScreen: (state, action) => {
      state.screen = action.payload;
    },
    setBoth: (state, action) => {
      state.display = action.payload.display;
      state.screen = action.payload.screen;
    },
    resetPageState: () => initialState, // Reset to initial state
  },
});

// Exports all actions, Action creators are generated for each case reducer function
export const { setDisplay, setScreen, setBoth, resetPageState } =
  pageSlice.actions;

export default pageSlice.reducer;
