import React, { useState, useEffect } from "react";
import CardHeading from "./CardHeading";
import { DeleteCompany, GetCompany } from "../apiCalls/CompanyCalls";
import { GetLocation, DeleteLocation } from "../apiCalls/LocationCalls";
import { GetRegion, DeleteRegion } from "../apiCalls/RegionCalls";
import { GetUser, DeleteUser } from "../apiCalls/UserCalls";
import { titleCase } from "../helpers/titleCase";
import { useAlert } from "../context/AlertContext";

export default function ConfirmDelete(props) {
  const scope = props.scope || "company"; // decide which data to get based on if it is company, region, or location
  const passedInId = props.id || 0; // id to use if for a specific component
  const { addAlert } = useAlert();
  const [entitiyInfo, setEntityInfo] = useState({});

  useEffect(() => {
    if (passedInId !== 0) {
      //choose which function to call
      let infoFunction;
      switch (scope) {
        case "company":
          infoFunction = () => GetCompany(passedInId);
          break;
        case "region":
          infoFunction = () => GetRegion(passedInId);
          break;
        case "location":
          infoFunction = () => GetLocation(passedInId);
          break;
        case "user":
          infoFunction = () => GetUser(passedInId);
          break;
        default:
          infoFunction = null;
      }

      if (infoFunction) {
        infoFunction()
          .then(async (response) => {
            if (response.success) {
              let result = response.data;
              let deleteData = {};

              if (scope === "company") {
                deleteData.name = result.name;
                deleteData.description = result.description;
              }
              if (scope === "region") {
                deleteData.name = result.name;
                deleteData.description = result.description;
                let company_info = await GetCompany(result.company_id);
                deleteData.company = company_info.data.name;
              }
              if (scope === "location") {
                deleteData.name = result.name;
                deleteData.description = result.description;
                let company_info = await GetCompany(result.company_id);
                let region_info = await GetRegion(result.region_id);
                deleteData.company = company_info.data.name;
                deleteData.region = region_info.data.name;
              }
              if (scope === "user") {
                deleteData.name = result.first_name + " " + result.last_name;
                let company_info = await GetCompany(result.company_id);
                let region_info = await GetRegion(result.region_id);
                let location_info = await GetLocation(result.location_id);
                deleteData.company = company_info.data.name;
                deleteData.region = region_info.data.name;
                deleteData.location = location_info.data.name;
              }
              setEntityInfo(deleteData);
            }
          })
          .catch((error) => {
            console.error(error);
            addAlert("An error occurred retrieving the data", "error", true);
          });
      }
    }
  }, [scope, passedInId]);

  function deleteItem() {
    const deleteInfoFunction =
      scope === "company"
        ? DeleteCompany
        : scope === "location"
        ? DeleteLocation
        : scope === "region"
        ? DeleteRegion
        : scope === "user"
        ? DeleteUser
        : null;

    if (deleteInfoFunction) {
      deleteInfoFunction(passedInId)
        .then((result) => {
          if (result && result.success) {
            addAlert(
              `${titleCase(scope)} Deleted successfully`,
              "success",
              false
            );

            // Close the modal on successful deletion
            if (props.onClose) {
              props.onClose(); // close the modal
            }
            // Any additional post-operation logic
            if (props.onSuccessfulOperation) {
              props.onSuccessfulOperation();
            }
          } else {
            addAlert(
              result.message || "An unknown error occurred",
              "error",
              true
            );
          }
        })
        .catch((error) => {
          console.error(error);
          addAlert("An error occurred while deleting", "error", true);
        });
    }
  }

  return (
    <>
      <CardHeading
        title={`Delete ${titleCase(scope)} Confirmation`}
        description={`Are you sure you want to delete this ${titleCase(
          scope
        )}? This action cannot be reversed.`}
      />

      <div className="delete-item-text-wrapper">
        <h2>
          {titleCase(scope)}: {entitiyInfo.name}
        </h2>

        {/* show description if it is company, region, or location */}
        {scope === "company" || scope === "region" || scope === "location" ? (
          <>
            <h3>{titleCase(scope)} Description:</h3>
            <p>{entitiyInfo.description}</p>
          </>
        ) : null}

        {/* show company if it is region, location, or user */}
        {scope === "region" || scope === "location" || scope === "user" ? (
          <p>Company: {entitiyInfo.company}</p>
        ) : null}

        {/* show region if it is location or user */}
        {scope === "location" || scope === "user" ? (
          <p>Region: {entitiyInfo.region}</p>
        ) : null}

        {/* show location if it is user */}
        {scope === "user" ? <p>Location: {entitiyInfo.location}</p> : null}
      </div>

      <div className="form-bottom-button-wrapper">
        <button className="button-blue" onClick={deleteItem}>
          Delete {titleCase(scope)}
        </button>
      </div>
    </>
  );
}
