import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CardHeading from "../../components/CardHeading";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"; // You might need to import the default styles.
import { useState } from "react";
import { GetAllTrainees } from "../../apiCalls/TraineeCalls";
import { GetAllCheckIns, AddCheckIn } from "../../apiCalls/CheckInCalls";
import { useSelector } from "react-redux";
import moment from "moment"; // Make sure to install moment if not already installed (npm install moment)
import Select from "react-select";
import { useAlert } from "../../context/AlertContext";

function AddCheckInForm(props) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const [trainees, setTrainees] = useState([]); // for storing trainees
  const [checkIns, setCheckIns] = useState([]); // for storing check-ins
  const location_id = useSelector((state) => state.location.id);
  const [selectedTrainee, setSelectedTrainee] = useState(null); // For storing selected trainee
  const [statusMessage, setStatusMessage] = useState(""); // For storing the status message
  const [submitCheckInType, setSubmitCheckInType] = useState(1); // For controlling the display of the check-out time input
  const { addAlert } = useAlert();

  // Fetch data when the component mounts (new each time component renders this way)
  useEffect(() => {
    async function fetchData() {
      const traineesResult = await GetAllTrainees("location", location_id);
      const checkinResult = await GetAllCheckIns("location", location_id);

      if (traineesResult.success) {
        setTrainees(
          traineesResult.data.map((trainee) => ({
            value: trainee.id, // Ensure this is the correct property for trainee's ID
            label: `${trainee.first_name} ${trainee.last_name}`,
            ...trainee, // Spread the rest of trainee properties if needed elsewhere
          }))
        );
      }

      if (checkinResult.success) {
        // Sort check-ins from newest to oldest
        const sortedCheckIns = checkinResult.data.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setCheckIns(sortedCheckIns);
      }
    }
    fetchData();
  }, [location_id]);

  // connect the date time to the form
  useEffect(() => {
    register("checkin_date", { required: true });
    register("checkin_time", { required: true });
    setValue("checkin_date", moment().format("MM/DD/YYYY"));
    setValue("checkin_time", moment().format("HH:mm:ss"));
  }, [register, setValue]);

  // Handler for trainee select
  const handleTraineeChange = (selectedOption) => {
    setSelectedTrainee(selectedOption);
    setValue("trainee", selectedOption ? selectedOption.value : ""); // Store the selected trainee's ID in the form

    // Find the latest check-in for the selected trainee
    const latestCheckIn = checkIns.find(
      (checkIn) => checkIn.trainee_id === selectedOption.value
    );

    if (latestCheckIn) {
      // Format the timestamp into a readable date and time
      const checkInDateTime = moment(latestCheckIn.timestamp);
      const formattedDate = checkInDateTime.format("MM/DD/YYYY"); // Date in MM/DD/YYYY format
      const formattedTime = checkInDateTime.format("hh:mm a"); // Time in 12-hour format with am/pm

      const traineeName = `${selectedOption.label}`;
      const action = latestCheckIn.type === 1 ? "checked in" : "checked out";

      setStatusMessage(
        `${traineeName} last ${action} at ${formattedTime} on ${formattedDate}. Do you want to check them ${
          action === "checked in" ? "out" : "in"
        }?`
      );

      // set value for submitting a new check in/out
      //1 is check in, 0 is check out
      if (latestCheckIn.type === 1) {
        setSubmitCheckInType(0);
      }
    } else {
      // Handle case where there is no check-in record for the selected trainee
      setStatusMessage(
        `${selectedOption.label} has no check-in records. Ready to check in.`
      );
    }
  };

  // Function to restrict selection of future times
  const restrictFutureTimes = (current) => {
    const now = moment();
    return current.isBefore(now);
  };

  const submit = (data) => {
    // Combine the date and time into a single string then into a moment object and convert to timestamp
    const dateTimeString = `${data.checkin_date} ${data.checkin_time}`;
    const timestamp = moment(dateTimeString, "MM/DD/YYYY HH:mm:ss").valueOf();

    let trainee = trainees.find((t) => t.id === selectedTrainee.value);

    let new_checkin = {
      trainee_id: trainee.id,
      company_id: trainee.company_id,
      region_id: trainee.region_id,
      location_id: trainee.location_id,
      timestamp: timestamp,
      type: submitCheckInType,
    };

    AddCheckIn(new_checkin).then((response) => {
      if (response.success) {
        addAlert("Visitor Record Added", "success", false);
        if (props.onClose) {
          props.onClose(); // close the modal
        }
        if (props.onSuccessfulOperation) {
          props.onSuccessfulOperation();
        }
      } else {
        addAlert(response.message, "error", false);
      }
    });
  };

  return (
    <>
      <CardHeading title="Add Visitor Record" />

      <form onSubmit={handleSubmit(submit)}>
        <div className="row">
          <div className={`half-width ${errors["trainee"] ? "has-error" : ""}`}>
            <label htmlFor="trainee">Trainee *</label>
            <Select
              name="trainee"
              value={selectedTrainee}
              onChange={handleTraineeChange}
              options={trainees}
              classNamePrefix={
                errors["trainee"] ? "react-select has-error" : "react-select"
              }
              placeholder={`Select a trainee`}
            />
          </div>
        </div>

        {/* Trainee Selected */}
        <div className="add-visitor-form-bottom-wrapper">
          {selectedTrainee && (
            <>
              <p className="status-message">{statusMessage}</p>
              <div className="row">
                <div
                  className={`half-width ${
                    errors["checkin_date"] ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="checkin_date">Date *</label>
                  <Datetime
                    inputProps={{
                      name: "checkin_date",
                      placeholder: "Select Date",
                    }}
                    value={new Date()}
                    onChange={(date) => {
                      if (moment.isMoment(date) && date.isValid()) {
                        // Check if date is valid
                        setValue("checkin_date", date.format("YYYY-MM-DD")); // Store in desired format
                      } else {
                        // Handle invalid or incomplete date input
                        setValue("checkin_date", ""); // Clear the value or handle as needed
                      }
                    }}
                    isValidDate={restrictFutureTimes}
                    closeOnSelect
                    timeFormat={false}
                  />
                </div>

                <div
                  className={`half-width ${
                    errors["checkin_time"] ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="checkin_time">Time *</label>
                  <Datetime
                    inputProps={{
                      name: "checkin_time",
                      placeholder: "Select Time",
                    }}
                    value={new Date()}
                    onChange={(time) => {
                      if (moment.isMoment(time) && time.isValid()) {
                        // Check if time is valid
                        setValue("checkin_time", time.format("HH:mm:ss")); // Store in desired format
                      } else {
                        // Handle invalid or incomplete time input
                        setValue("checkin_time", ""); // Clear the value or handle as needed
                      }
                    }}
                    isValidDate={restrictFutureTimes}
                    closeOnSelect
                    dateFormat={false}
                    timeFormat={true}
                  />
                </div>
              </div>
              <div className="error-message">
                {errors.checkin_date && <p>Date is required.</p>}
                {errors.checkin_time && <p>Time is required.</p>}
              </div>
              <div className="form-bottom-button-wrapper">
                <button type="submit" className="button button-blue">
                  Add Record
                </button>
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
}

export default AddCheckInForm;
