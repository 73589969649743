import {
  PostService,
  GetService,
  PutService,
  DeleteService,
} from '../services/GeneralServices';

/* get the information for a company */
export const GetRegion = async (regionId) => {
  try {
    const response = await GetService('region/id/' + regionId);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetAllRegions = async (type, id = null) => {
  // global is default
  let url = 'regions';
  if (id !== null) {
	switch (type) {
		case 'company':
		url = 'regions/company/' + id;
		break;
		default:
		url = 'regions';
		break;
	}
  }
  try {
    const response = await GetService(url);
    if ((response.success = true)) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const AddRegion = async (formData) => {
  try {
    const response = await PostService('region', formData);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* get all info for the region */
export const UpdateRegion = async (region_id, formData) => {
  // will prob need to reformat this data
  try {
    const response = await PutService('region/id/' + region_id, formData);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

/* delete single region location */
export const DeleteRegion = async (region_id) => {
  try {
    const response = await DeleteService('region/id/' + region_id);
    if (response) {
      return {
        success: true,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};
