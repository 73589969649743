import { GetService, PostService, DeleteService } from '../services/GeneralServices';

export const GetAllTrainees = async (type, id = null) => {
  // global is default
  let url = 'trainees';
  if(id !== null){
	switch (type) {
		case 'company':
		url = 'trainees/company/' + id;
		break;
		case 'region':
		url = 'trainees/region/' + id;
		break;
		case 'location':
		url = 'trainees/location/' + id;
		break;
		default:
		url = 'trainees';
		break;
	}
}
  try {
    const response = await GetService(url);
    if (response) {
      return {
        success: true,
        data: response,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: String(error),
    };
  }
};

export const GetTrainee = async (id) => {
	try {
		const response = await GetService('trainee/id/' + id);
		if (response) {
			return {
				success: true,
				data: response,
			};
		}
	} catch (error) {
		return {
			success: false,
			message: String(error),
			};
	}
}

export const UpdateTrainee = async (id, formData) => {
	try {
		const response = await PostService('trainee/update/'+id, formData);
		if (response) {
			return {
				success: true,
			};
		}
	} catch (error) {
		return {
			success: false,
			message: String(error),
		};
	}
}

export const DeleteTrainee = async (id) => {
	try {
		const response = await DeleteService('trainee/id/'+id);
		if (response) {
			return {
				success: true,
			};
		}
	} catch (error) {
		return {
			success: false,
			message: String(error),
		};
	}
}
